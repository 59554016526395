import React from "react";
import { ChakraProvider, Box, Flex, Heading, Text, Button, Divider, useMediaQuery } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import homeStock1 from "../../images/home_stock1.jpg";

function Banner({ height }) {
    const [isSmallScreen] = useMediaQuery('(max-width: 600px)');

    return (
        <ChakraProvider>
            <Box
                height={height}
                width={"100%"}
                bgImage={(`${homeStock1}`)}
                bgSize={"cover"}
                opacity={"3 "}
                mx={isSmallScreen ? "auto" : "none"}
            >
                <Flex
                    direction={"column"}
                    width={isSmallScreen ? "85vw" : "33vw"}
                    fontWeight={"semibold"}
                    mx={isSmallScreen ? "auto" : "3em"}
                    my={isSmallScreen ? "4em" : "5em"}

                    color={"white"}

                >
                    <Heading as={"h1"} fontSize={isSmallScreen ? "1.5em" : "2em"} mx={isSmallScreen ? "auto" : "none"}>Your Insurance Agency</Heading>
                    <Text fontSize={isSmallScreen ? "sm" : "md"} mx={isSmallScreen ? "auto" : "none"}>in New York, Connecticut, and Massachusetts</Text>
                    <Divider mt={"1em"} width={"55%"} mx={isSmallScreen ? "auto" : "none"} />
                    <Text
                        fontSize={isSmallScreen ? "md" : "lg"}
                        fontWeight={"700"}
                        mt={"em"}
                        maxW={isSmallScreen ? "85%" : "60%"}
                        mx={isSmallScreen ? "auto" : "none"}
                        textAlign={isSmallScreen ? "center" : "left"}
                    >
                        Specializing in Home Insurance, Auto Insurance, and Business Insurance throughout the Capital Region of New York
                    </Text>
                    <Box width={isSmallScreen ? "70%" : "30%"} mx={isSmallScreen ? "auto" : "none"}>
                        <Link to={"/quote"}>
                            <Button
                                borderRadius={"20"}
                                mt={"2em"}
                                border={"1px"}
                                bg={"transparent"}
                                color={"white"}
                                _hover={{ bg: "#5F5FF7", border: "none" }}
                                width="100%"
                            >
                                GET A QUOTE
                            </Button>
                        </Link>
                    </Box>
                </Flex>
            </Box>
        </ChakraProvider>
    );
}

export default Banner;

