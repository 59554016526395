import React from 'react';
import { Box, Heading, List, ListItem, Link, VStack } from '@chakra-ui/react';
import TopHeaderBar from './components/Top';
import NavigationHeader from './components/NavigationHeader';
import Footer from './components/Footer';
import GetAQuote from './components/GetAQuote';


const Sitemap = () => {
  return (
    <Box height={"100%"} >
        <TopHeaderBar/>
        <NavigationHeader/>
    <VStack align="start" spacing={5}>
      <Heading as="h1">Sitemap</Heading>
      <Box>
        <Heading as="h3" size="md">Main Pages</Heading>
        <List>
          <ListItem><Link href="/">Home</Link></ListItem>
          <ListItem><Link href="/agency-partners">Agency Partners</Link></ListItem>
          <ListItem><Link href="/file-claim">File a Claim</Link></ListItem>
          <ListItem><Link href="/pay-bill">Pay Bill</Link></ListItem>
          <ListItem><Link href="/contact">Contact Us</Link></ListItem>
          <ListItem><Link href="/quote">Get a Quote</Link></ListItem>
          <ListItem><Link href="/about">About Us</Link></ListItem>
          <ListItem><Link href="/our-team">Our Team</Link></ListItem>
          <ListItem><Link href="/careers">Careers</Link></ListItem>
          <ListItem><Link href="/landing1">Landing Page 1</Link></ListItem>
          <ListItem><Link href="/landing2">Landing Page 2</Link></ListItem>
        </List>
      </Box>
      <Box>
        <Heading as="h3" size="md">Personal Insurance</Heading>
        <List>
          <ListItem><Link href="/insurance/personal">Overview</Link></ListItem>
          <ListItem><Link href="/insurance/personal/home">Home Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/personal/rv">RV Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/personal/umbrella">Umbrella Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/personal/motorcycle">Motorcycle Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/personal/boat">Boat Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/personal/valuable-possessions">Valuable Possession Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/personal/renters">Renters Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/personal/offroad">Off-Road Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/personal/auto">Auto Insurance</Link></ListItem>
        </List>
      </Box>
      <Box>
        <Heading as="h3" size="md">Business Insurance</Heading>
        <List>
          <ListItem><Link href="/insurance/business">Overview</Link></ListItem>
          <ListItem><Link href="/insurance/business/liquor">Liquor Liability Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/flood">Commercial Flood Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/directors">Directors And Officers Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/cybersecurity">Cyber Security Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/commercial-umbrella">Commercial Umbrella Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/workers-compensation">Workers Compensation Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/commercial-property">Commercial Property Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/business-auto">Business Auto Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/general-liability">General Liability Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/business-owners">Business Owners Policy Insurance</Link></ListItem>
          <ListItem><Link href="/insurance/business/omissions">Omissions</Link></ListItem>
        </List>
      </Box>
    </VStack>
    <GetAQuote/>
    <Footer/>
    </Box>
  );
};

export default Sitemap;
