import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Captcha from "react-captcha-code"
import {
  ChakraProvider,
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Checkbox,
  CheckboxGroup,
  Select
} from '@chakra-ui/react';

function EasyQuoteForm({ borderRadius, width }) {
  const [insuranceType, setInsuranceType] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [captchaCode, setCaptchaCode] = useState('');
  const [userInputCaptcha, setUserInputCaptcha] = useState('');

  const handleChangeCaptcha = (value) => {
    setCaptchaCode(value);
  };

  const validateCaptcha = () => {
    if (captchaCode !== userInputCaptcha) {
      alert('Invalid CAPTCHA. Please try again.');
      return false;
    }
    return true;
  };

  const form = useRef()

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateCaptcha()) {
      return;
    }
    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_EASY_QUOTE_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    ).then(
      (result) => {
        alert('thank you for messaging!')
        window.location.reload()
      },
      (error) => {
        console.log(error.text)
      }
    )
  };

  const personalOptions = ['Home', 'Auto', 'Motorcycle', 'Renters', 'Off-road', 'RV', 'Snowmobile', 'Watercraft', 'Umbrella', 'Valuable Possession'];
  const businessOptions = ['BOP', 'General Liability', 'Commercial Auto', 'Commercial Property', 'Worker’s Comp', 'Commercial Umbrella', 'Cyber Liability', 'Errors & Omissions Liability', 'Directors & Officers Liability', 'Commercial Flood', 'Commercial Crime', 'Liquor Liability'];

  const handleChange = (e) => {
    setInsuranceType(e.target.value);
    setSelectedTypes([]);
  }

  const handleTypesChange = (values) => {
    setSelectedTypes(values);
  }

  return (
    <ChakraProvider>
      <Box height={"100%"} width={"100%"}>
        <Flex
          borderRadius={borderRadius || "0"}
          width={"100%"}
          alignContent={"center"}
          flexDirection={"column"}
          bgGradient="linear(to-r, gray.100, gray.200)"
          py={16}
        >
          <VStack mx={"auto"} mb={"2em"} textAlign={"center"} spacing={6}>
            <Heading
              as={"h1"}
              fontSize={{ base: "3xl", md: "4xl" }}
              fontWeight="bold"
              color={"gray.700"}
            >
              Start a Conversation and Get A Quote!
            </Heading>
            <Heading
              as={"h2"}
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="medium"
              color={"gray.600"}
            >
              Let us help you with any of your insurance needs!
            </Heading>
            <Text
              maxW={{ base: "80vw", md: "40vw" }}
              fontSize={"lg"}
              fontWeight="medium"
              lineHeight={"tall"}
              color={"gray.500"}
            >
              If you have any questions about policies, billing, coverage, claims or
              are just getting started please contact us today and we will get back
              to you as soon as possible.
            </Text>
          </VStack>
          <Box
            width={width || { base: "80%", md: "40%" }}
            mx={"auto"}
            py={8}
            backgroundColor="white"
            borderRadius="lg"
            boxShadow="lg"
            px={8}
          >
            <form onSubmit={onSubmit} ref={form}>
              <VStack spacing={6} mt={4}>
                <FormControl isRequired id="firstName">
                  <FormLabel color={"black"}>First Name</FormLabel>
                  <Input
                    type="text"
                    name='first_name'
                    required
                    bg="white"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{ borderColor: 'gray.500', boxShadow: '0 0 0 1px teal.500' }}
                  />
                </FormControl>
                <FormControl isRequired id="lastName">
                  <FormLabel color={"black"}>Last Name</FormLabel>
                  <Input
                    type="text"
                    name='last_name'
                    required
                    bg="white"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{ borderColor: 'gray.500', boxShadow: '0 0 0 1px teal.500' }}
                  />
                </FormControl>
                <FormControl isRequired id="phoneNumber">
                  <FormLabel color={"black"}>Phone Number</FormLabel>
                  <Input
                    name='phone'
                    type="tel"
                    required
                    bg="white"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{ borderColor: 'gray.500', boxShadow: '0 0 0 1px teal.500' }}
                  />
                </FormControl>
                <FormControl isRequired id="email">
                  <FormLabel color={"black"}>Email</FormLabel>
                  <Input
                    type="email"
                    name='email'
                    required
                    bg="white"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{ borderColor: 'gray.500', boxShadow: '0 0 0 1px teal.500' }}
                  />
                </FormControl>
                <FormControl id="insuranceType">
                  <FormLabel color={"black"}>Personal or Business insurance?</FormLabel>
                  <Select name='insurance-type' value={insuranceType} onChange={handleChange}>
                    <option value="">Select...</option>
                    <option value="Personal">Personal</option>
                    <option value="Business">Business</option>
                  </Select>
                </FormControl>
                {insuranceType && (
                  <FormControl id="specificType">
                    <FormLabel color={"black"}>What type(s) of insurance?</FormLabel>
                    <CheckboxGroup value={selectedTypes} onChange={handleTypesChange}>
                      {(insuranceType === 'Personal' ? personalOptions : businessOptions).map((type) => (
                        <Checkbox name='specific-type' key={type} value={type}>{type}</Checkbox>
                      ))}
                    </CheckboxGroup>
                  </FormControl>
                )}
                <FormControl isRequired id="captcha">
                <FormLabel>Enter the code below:</FormLabel>
                  <Captcha
                    charset="alphanumeric" // or 'numeric'
                    codeLength={6}
                    onChange={handleChangeCaptcha}
                  />
                  <Input
                    type="text"
                    value={userInputCaptcha}
                    onChange={e => setUserInputCaptcha(e.target.value)}
                    required
                  />
                </FormControl>
                <Button type="submit" color={"white"} bg={"purple.700"} _hover={{ bg: "purple.800" }} size="lg">
                  Submit
                </Button>
              </VStack>
            </form>
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default EasyQuoteForm;

