import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Link,
  Divider,
  Button,
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import Directors from "../../images/business-insurance/directors.jpg";

function DirectorsAndOfficersInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box bg={bgColor} minHeight="100%">
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"Directors and Officers Insurance"}
          footer={"High Quality Directors And Officers Insurance"}
          img={Directors}
          fontSize={"1.5em"}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What is Directors and Officers Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Directors and Officers insurance (D&O) coverage is designed to
                protect individuals from personal losses if they are sued due to
                their service as an executive or director of a company. A D&O
                insurance coverage protects against personal damages and can
                assist a corporation or organization in reimbursing legal bills
                or other costs in litigation defending such persons.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Do I Need D&O Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                The size and type of your company determine it. However, D&O
                insurance should be thoroughly addressed in general. So, while
                D&O insurance isn't required for every firm, it's safe to say
                that any company with a board of directors would be cautious
                about seeking D&O insurance.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What Does D&O Insurance Cover?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                When the insured is held responsible, D&O insurance often covers
                legal bills, settlements, and financial damages. Failure to
                comply with legislation, lack of corporate governance, creditor
                claims, and reporting mistakes are all common issues among
                higher organization members. Fraud, criminal conduct, and
                disputes between managers from the same organization are not
                protected.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                The Benefits of Directors and Officers (D&O) Liability Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                D&O insurance can cover all court costs and legal spending
                incurred by the executives or firms in defending themselves. It
                can also cover a portion or all of the settlement fees. Both
                employers and executives profit from director's and officer's
                liability insurance. It shields CEOs and board members from
                personal culpability in the event of litigation while also
                protecting profit and nonprofit employers from having to pay
                these costs out of pocket.
              </Text>
              
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Save Time and Money with Lia Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              If you need Director's & Officer's Liability insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different Director's & Officer's Liability insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your business!
              </Text>
              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default DirectorsAndOfficersInsurance;
