import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Link,
  Button,
  Divider,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import Property from "../../images/business-insurance/property.jpg";

function CommercialPropertyInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");


  return (
    <Box bg={bgColor} minHeight="100%">
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"Commerial Property Insurance"}
          footer={"Keep Your Commercial Property Safe"}
          img={Property}
          fontSize={"1.5em"}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What is Commercial Property Insurance or Business Personal Property Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Commercial property insurance protects your business's physical assets plus the tools and equipment you use to operate your business against theft, vandalism, storms, explosions, burst pipes, and fire.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                What Does Commercial Property Insurance Cover?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              Your building, everything inside it, and the area around it are all covered by commercial property insurance, including:
              </Text>
              <UnorderedList>
                <ListItem>Computers</ListItem>
                <ListItem>Furniture and equipment</ListItem>
                <ListItem>Exterior signs</ListItem>
                <ListItem>Fence and landscaping</ListItem>
                <ListItem>Important documents</ListItem>
                <ListItem>Inventory</ListItem>
                <ListItem>Others' property</ListItem>
                <ListItem>Building</ListItem>
                <ListItem>Equipment</ListItem>
                <ListItem>Tools</ListItem>
                <ListItem>Personal property</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Who Needs Commercial Property Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              Commercial property insurance is beneficial for any company with a  location that has valuable or essential resources, such as inventory or equipment. Retailers, distributors, manufacturers, and experts across various industries will benefit. Parties like landlords and mortgage lenders may also ask for confirmation of commercial property coverage.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                How Does Commercial Property Insurance Work?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              Your commercial property insurance will cover up to the coverage limit after the policy's deductible, the expenses to repair or replace the damaged building structure, or items. Policies are written as either replacement cost or actual cash value
              </Text>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Save Time and Money with Lia Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              If you need Commercial Property or Business Personal Property insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different Commercial Property or Business Personal Property insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your business!
              </Text>
              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default CommercialPropertyInsurance;
