import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Link,
  Divider,
  Button,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import UmbrellaInsurancePic from "../../images/personal-insurance/UmbrellaInsurance.jpg";

function CommercialUmbrellaInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");


  return (
    <Box bg={bgColor} minHeight="100%">
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"Umbrella Insurance"}
          footer={"Protect Yourself at an Affordable Premium"}
          img={UmbrellaInsurancePic}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                What is Commercial Umbrella Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Commercial umbrella insurance provides an additional layer of liability protection by paying charges that exceed the liability coverage.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What does Commercial Umbrella Insurance cover?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Commercial umbrella insurance covers costs like, medical expenses, attorney fees, and damages when your company faces a lawsuit. If your underlying coverage hits its limitations during the year due to several claims or a hefty settlement, your business umbrella insurance fills the gap by covering any further responsibility. When your underlying insurance policy limitations are reached, you must pay out of pocket if you do not have commercial umbrella coverage.
              </Text>
              <Text fontSize="lg" mb={"1.5em"}>
                Commercial Umbrella Coverage can help cover the following:
              </Text>
              <UnorderedList>
                <ListItem>Physical harm, such as a customer being injured at your place of business</ListItem>
                <ListItem>Property damage sustained by your company</ListItem>
                <ListItem>Lawsuits filed by consumers or workers who have suffered work-related injuries or illnesses</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Do I need Commercial Umbrella Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Companies face the possibility of being sued, whether by customers, other businesses, or even staff; umbrella insurance is suggested for business owners. Everyone who owns property has significant assets, or runs a business should strongly consider umbrella insurance. f you are sued for an extensive amount of money and found accountable, and you do not have proper liability insurance coverage to pay the cost, you may be held responsible for the out of pocket difference.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                How Does Commercial Umbrella Insurance Work?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Commercial umbrella insurance covers both general liability and business auto insurance limits. This coverage is often known as business umbrella insurance. Commercial umbrella insurance can help cover the costs exceeding the limits of your commercial auto and general liability policy.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Who Should Purchase Commercial Umbrella Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                The more your businesses interact with clients and consumers in person, the greater their liability concerns. Your risk factors may increase if your staff works with big machinery or risky equipment.
              </Text>
              <UnorderedList>
                <ListItem>Interacts with consumers regularly</ListItem>
                <ListItem>Works on someone else's land</ListItem>
                <ListItem>Open to the public during open hours</ListItem>
                <ListItem>General coverage for enhanced peace of mind</ListItem>
              </UnorderedList>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Save Time and Money with Lia Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              If you need Commercial Umbrella insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different Commercial Umbrella insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your business!
              </Text>
              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default CommercialUmbrellaInsurance;
