import React from "react";
import TopHeaderBar from "./components/Top";
import FooterBar from "./components/GetAQuote";
import NavigationHeader from "./components/NavigationHeader";
import { Box, ChakraProvider } from "@chakra-ui/react"
import ContactUsForm from "./components/ContactUsForm";
import Footer from "./components/Footer";

function ContactUs() {
    return (
        <ChakraProvider>
          <Box height={"100%"}>
            <TopHeaderBar/>
            <NavigationHeader/>
            <ContactUsForm/>
            <Footer mb={12}/>
            <FooterBar />
            
          </Box>
        </ChakraProvider>
      );
      
}

export default ContactUs;