import React from "react";
import TopHeaderBar from "./components/Top";
import FooterBar from "./components/GetAQuote";
import Footer from "./components/Footer"
import NavigationHeader from "./components/NavigationHeader";
import { Box, ChakraProvider } from "@chakra-ui/react"
import GetAQuoteForm from "./components/GetAQuoteForm";
import Banner from "./pages/Personal-Insurance/Banner"
import stock from "./images/business-insurance/stock.jpeg"

function GetAQuoteEZLynx() {
    return (
        <ChakraProvider>
          <Box>
            <TopHeaderBar/>
            <NavigationHeader/>
            <Banner header={"Get a Quote From Lia Insurance Today!"} footer={"Affordable Insurance Rates in NY, CT, and MA."} img={stock} fontSize={"1.5em"} />
            <GetAQuoteForm/>
            <Footer/>
            <FooterBar />
          </Box>
        </ChakraProvider>
      );
      
}

export default GetAQuoteEZLynx;