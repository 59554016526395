import React from "react";
import { ChakraProvider, Text, Heading, Flex } from "@chakra-ui/react";



function Banner({header, footer, img, fontSize}) {
    return (
        <ChakraProvider>
            <Flex height={"33vh"} width={"100%"} m={"none"} p={"none"} bgImage={(`${img}`)} bgSize={"cover"} backgroundPosition={"center center"} opacity={"2"}>
                <Flex direction={"column"} justify={"center"} mx={"auto"}  fontWeight={"semibold"} color={"white"}>
                    <Heading as={"h1"} mx={"auto"} fontSize={`${fontSize || "2em"}`}>{header}</Heading>
                    <Text width={"60%"} mx={"auto"} textAlign={"center"}>{footer}</Text>
                </Flex>
            </Flex>


        </ChakraProvider>

    )

}

export default Banner;