import React, { createContext, useContext, useState } from 'react';
import encompass from "./images/encompass.png";
import foremost from "./images/foremost.png";
import foremostinsurancegroup from "./images/foremostinsurancegroup.png";
import hagerty from "./images/hagerty.png";
import leatherstockingcooperative from "./images/leatherstockingcooperative.png";
import nationalgeneral from "./images/nationalgeneral.png";
import newyorkcentralmutual from "./images/newyorkcentralmutual.png";
import preferredmutual from "./images/preferredmutual.png";
import progressive from "./images/progressive.png";
import safeco from "./images/safeco.jpg";
import satelliteagency from "./images/satelliteagencynetwork.png";
import travelers from "./images/travelers.png";
import uticanationalinsurancegroup from "./images/uticanationalinsurancegroup.jpg";
import BoatInsurance  from "./images/personal-insurance/BoatInsurance.jpg"
import CarInsurance  from "./images/personal-insurance/CarInsurance.jpg"
import HomeInsurance  from "./images/personal-insurance/HomeInsurance.jpg"
import MotorcycleInsurance  from "./images/personal-insurance/MotorcycleInsurance.jpg"
import OffroadInsurance  from "./images/personal-insurance/OffroadInsurance.jpg"
import RentersInsurance  from "./images/personal-insurance/RentersInsurance.jpg"
import RVInsurance  from "./images/personal-insurance/RVInsurance.jpg"
import UmbrellaInsurance  from "./images/personal-insurance/UmbrellaInsurance.jpg"
import ValuablePossesionInsurance  from "./images/personal-insurance/ValuablePossessionInsurance.jpg"


const ImageContext = createContext()

export const useImages = () => {
    return useContext(ImageContext)
};

export const ImageProvider = ({ children }) => {
    const [images, setImages] = useState({
        encompass, foremost, foremostinsurancegroup, hagerty, leatherstockingcooperative, nationalgeneral, newyorkcentralmutual, preferredmutual,
        progressive, safeco, satelliteagency, travelers, uticanationalinsurancegroup, BoatInsurance, CarInsurance, HomeInsurance, MotorcycleInsurance, OffroadInsurance,
        RentersInsurance, RVInsurance, UmbrellaInsurance, ValuablePossesionInsurance
    })

    return (
        <ImageContext.Provider value={{images, setImages}}>
            {children}
        </ImageContext.Provider>
    )
}

