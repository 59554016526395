import React from 'react';
import { HStack, Text, Link, LinkBox, LinkOverlay, IconButton, useMediaQuery } from '@chakra-ui/react';
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons';
import { FaFacebook } from 'react-icons/fa';
import ReactGA from "react-ga4"

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)

const handleButtonClick = () => {
  const event = ReactGA.event('phone_call', {
    category: 'Clicks',
    action: 'Phone Call',
    label: 'tel:+15185793153',
  });
  console.log(event)

}


function TopHeaderBar() {
  const [isSmallScreen] = useMediaQuery('(max-width: 800px)');

  const mobileHeader = () => (
    <HStack
      display={"flex"}
      align="center"
      spacing={2}
      py={2}
      px={4}
      bg="white"
      height="auto"
      width="100%"
      fontFamily={"body"}
    >
      <IconButton
        // onClick={handleButtonClick}
        as={Link}
        href="tel:+15185793153"
        aria-label="Call"
        icon={<PhoneIcon />}
        isRound
      />
      <IconButton
        as={Link}
        href="mailto:insurance@liagroup.com"
        aria-label="Email"
        icon={<EmailIcon />}
        isRound
      />
      <IconButton
        as={Link}
        href="https://www.facebook.com/liainsurancebrokers/"
        aria-label="Go to Facebook"
        icon={<FaFacebook />}
        isRound
      />
    </HStack>
  );

  const desktopHeader = () => (
    <HStack
      display={"flex"}
      align="end"
      justifyContent="end"
      spacing={2}
      py={2}
      px={4}
      bg="white"
      height="auto"
      width="100%"
    >
      <HStack spacing={2}>
        <PhoneIcon />
        <Text >
          Call:
          <LinkBox>
            <LinkOverlay onClick={handleButtonClick} href="tel:+15185793153">518-579-3153</LinkOverlay>
          </LinkBox>
        </Text>
      </HStack>

      <HStack spacing={2}>
        <EmailIcon />
        <Text>
          Email:
          <LinkBox>
            <LinkOverlay href="mailto:insurance@liagroup.com">insurance@liagroup.com</LinkOverlay>
          </LinkBox>
        </Text>
      </HStack>

      <HStack spacing={2}>
        <IconButton
          as={Link}
          href="https://www.facebook.com/liainsurancebrokers/"
          aria-label="Go to Facebook"
          icon={<FaFacebook />}
          isRound
        />
      </HStack>
    </HStack>
  );

  return isSmallScreen ? mobileHeader() : desktopHeader();
}

export default TopHeaderBar;

