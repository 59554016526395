import React from 'react';
import { useState } from 'react'
import {
  Box,
  IconButton,
  Stack,
  Heading,
  Text,
  Container,
  HStack,
  VStack,
  Center,
  useMediaQuery
} from '@chakra-ui/react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider from 'react-slick';
import { StarIcon } from "@chakra-ui/icons";


const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Testimonials() {
  const [slider, setSlider] = useState(null)
  const [isLargerThanMD] = useMediaQuery("(min-width: 768px)");

  const top = isLargerThanMD ? '50%' : '90%';
  const side = isLargerThanMD ? '40px' : '30%';

  const reviews = [{
    name: "Sarah R.",
    review: "Lia Insurance Agency saved me over $600 a year on my auto insurance. They are very responsive and friendly. I would highly recommend them to anyone.",
  },
  {
    name: "R D.",
    review: "We've had nothing but great experience with Lia insurance. Sean along with the other employees were extremely helpful! Would highly recommend."
  },
  {
    name: "Karen M.",
    review: "I was very impressed with the service and prices. Highly recommend."
  },
  {
    name: "Diane G.",
    review: "I have only positive things to say about my experience with Lia Insurance Agency. Both financially and in customer service. I asked them to evaluate my current auto, homeowners and personal liability policies to see if they could save me any money. I was shocked when they came back to me in a matter of minutes with an overall savings of over $1,100 a year! I was not expecting THAT much of a savings!"
  },
  {
    name: "Beth F.",
    review: "Right off the bat, they dropped my payments by $80 a month and still got me all the coverage I need for my lease. Switching from 1 lease to the next couldn't have been easier. Plus, I have someone who is constantly watching for lower rates for me and watches over my policy. If there's a better rate out there, Lia Insurance always lets me know, but won't have me move forward until they know 100% that its the best move for me."
  }
  ]

  return (
    <Box
      position={'relative'}
      height={['400px', '600px']}
      width={'full'}
      overflow={'hidden'}>

      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      <IconButton
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}
        display={isLargerThanMD ? "block" : "none"}>
        <BiLeftArrowAlt size="40px" />
      </IconButton>

      <IconButton
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}
        display={isLargerThanMD ? "block" : "none"}>
        <BiRightArrowAlt size="40px" />
      </IconButton>

      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {reviews.map((review, index) => (
          <Box
            key={index}
            height={['4xl', '6xl']}
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundColor={"#BC3B4A"}>

            <Container mx={"auto"} size="container.lg" height={['400px', '600px']} position="relative">

              {isLargerThanMD && (
                <VStack spacing={6}>
                  <Center>
                    <Text fontSize={["4xl", "6xl"]}>Testimonials</Text>
                  </Center>
                  <Center>
                    <Heading fontSize={["2xl", "4xl"]}>What our clients are saying</Heading>
                  </Center>
                </VStack>
              )}

              <Stack
                border={"1px solid white"}
                spacing={6}
                w={'95%'}
                maxW={'lg'}
                p={"12"}
                bgColor={"white"}
                borderRadius={"xl"}
                position="absolute"
                top="50%"
                transform="translate(0, -50%)"
                mt={isLargerThanMD ? "5em" : "none"}
                >

                <Text fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} color="gray.700">
                  {review.review}
                </Text>

                <HStack>
                  <Text color={"gray.700"}>
                    {review.name}
                  </Text>
                  <StarIcon color={"gold"}/>
                  <StarIcon color={"gold"}/>
                  <StarIcon color={"gold"}/>
                  <StarIcon color={"gold"}/>
                  <StarIcon color={"gold"}/>
                </HStack>
              </Stack>
            </Container>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}

export default Testimonials;

