import React from "react";
import { ChakraProvider, Flex  } from "@chakra-ui/react";
import Banner from "./banner";
import Summary from "./summary";
import Testimonials from "./testimonials";
import theme from "../../theme";
import InsuranceBanner from "./insurance-banner";
import personalInsuranceImg from "../../images/personal-insurance.jpg"
import businessInsuranceImg from "../../images/business-insurance.png"
import { AiOutlineHome } from "react-icons/ai"
import { FaRegMoneyBillAlt } from "react-icons/fa"




function Home() {
    const personalInsurance = ["Home", "Auto", "Motorcycle", "Renters", "Offroad", "RV", "Snowmobile", "Boat", "Umbrella", "Valuable Possessions"]
    const businessInsurance = ["Business Owners", "General Liability", "Business Auto", "Commercial Property", "Workers Compensation", "Commercial Umbrella"]
    return (
        <ChakraProvider theme={theme} >
            <Flex direction={"column"}>
                <Banner height={"50vh"}/>
                <Summary/>
                <InsuranceBanner logo={AiOutlineHome} kind={"personal"} heading={"Personal Insurance"} types={personalInsurance} img={personalInsuranceImg} alignment={"start"}  />
                <InsuranceBanner logo={FaRegMoneyBillAlt} kind={"business"} heading={"Business Insurance"} types={businessInsurance} img={businessInsuranceImg} alignment={"end"}  />
                <Testimonials/>
            </Flex>
        </ChakraProvider>

    )
}

export default Home;