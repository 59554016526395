import React from "react";
import { Heading, Flex, Button,  useBreakpointValue } from "@chakra-ui/react";
import { Link } from "react-router-dom"
import { AiOutlineArrowRight } from "react-icons/ai"

function InsuranceBanner({ heading, types, alignment, img, kind }) {
    const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
    const headingSize = useBreakpointValue({ base: "2xl", md: "4xl" });
    const paddingValue = useBreakpointValue({ base: "3%", md: "5%" });

    return (
      <Flex
        direction="column"
        minHeight="50vh"
        width="100%"
        alignItems={alignment}
        justifyContent="center"
        bgImage={`url(${img})`}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        p={paddingValue}
      >
        <Heading color="white" fontSize={headingSize} textAlign={alignment}>
          {heading}
        </Heading>

        {types.map((type, index) => (
          <Link to={`/insurance/${kind}/${type.toLowerCase().replace(' ', '-')}`}>
            <Button
              color="white"
              leftIcon={<AiOutlineArrowRight />}
              width="auto"
              border="none"
              bg="transparent"
              justifyContent={alignment === "start" ? "flex-start" : "flex-end"}
              _hover={{ color: 'whiteAlpha.800' }}
              _focus={{ boxShadow: 'none', outline: 'none' }}
              iconSpacing="0.5rem"
              size={buttonSize}
              mt={3} // Added some margin-top for better separation
            >
              {type} Insurance
            </Button>
          </Link>
        ))}
      <Link to={`/insurance/${kind}`}>
        <Button
          width="auto"
          color="white"
          leftIcon={<AiOutlineArrowRight />}
          justifyContent={alignment === "start" ? "flex-start" : "flex-end"}
          bg="transparent"
          _hover={{ color: 'whiteAlpha.800' }}
          _focus={{ boxShadow: 'none', outline: 'none' }}
          size={buttonSize}
          mt={3} // Added some margin-top for better separation
        >
          See all
        </Button>
        </Link>
      </Flex>
    );
}

export default InsuranceBanner;
