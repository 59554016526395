import React from "react";
import TopHeaderBar from "./components/Top";
import FooterBar from "./components/GetAQuote";
import Footer from "./components/Footer";
import NavigationHeader from "./components/NavigationHeader";
import { Box, ChakraProvider, Flex, VStack, useMediaQuery, Text } from "@chakra-ui/react";
import Banner from "./pages/Personal-Insurance/Banner";
import stock from "./images/business-insurance/stock.jpeg";

function GetAQuoteTool() {
    const smallScreen = useMediaQuery('(max-width: 800px)')

  return (
    <ChakraProvider>
      <Box>
        <TopHeaderBar />
        <NavigationHeader />
        <Banner
          header={"Get a Quote From Lia Insurance Today!"}
          footer={"Affordable Insurance Rates in NY, CT, and MA."}
          img={stock}
          fontSize={"1.5em"}
        />
        <Box width={"100%"} height={"1200px"}>
          <Flex
            height={"120%"}
            alignContent={"center"}
            flexDirection={"column"}
            bgGradient="linear(to-r, gray.100, gray.200)"
            py={16}
          >
            <VStack
              mx={"auto"}
              mb={"2em"}
              textAlign={"center"}
              p={6}
              borderRadius="lg"
              bg="white"
              spacing={6}
              w={smallScreen ? "90%" : "60%"}
              shadow="xl"
            >
              <Text fontSize="2xl" fontWeight="bold">
                Get Your Insurance Quote
              </Text>
              <Box
                width="100%"
                border="1px solid gray.200"
                borderRadius="lg"
                overflow={"hidden"}
              >
                <iframe title="quote tool" src="https://link.getfize.com/xzbgtvpswq?iframe=true&utm_source=iframe" style={{width: "400px", maxWidth: "100%", height: "900px", display: "block", margin:"auto", border: "0px"}} />
              </Box>
            </VStack>
          </Flex>
        </Box>
        <Footer />
        <FooterBar />
      </Box>
    </ChakraProvider>
  );
}

export default GetAQuoteTool;
