import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Divider,
  Link,
  Button,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import BusinessOwner from "../../images/business-insurance/business-owner.jpg";

function BusinessOwnersPolicyInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box bg={bgColor} minHeight="100%">
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"Business Owner's Insurance"}
          footer={"The Right Coverage For Business Owners"}
          img={BusinessOwner}
          fontSize={"2em"}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What Is Business Owner’s Policy Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Business insurance coverage, known as a Business Owner's Policy
                (BOP), combines business property and liability insurance. BOP
                insurance aids in protecting your company from claims brought
                about by fire, theft, and other covered events.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What Is Included in a Business Owner's Policy?
              </Heading>
              <UnorderedList>
                <ListItem>Business property insurance includes your owned or rented facility, your tools, and the equipment you employ to function are all protected by business property insurance.</ListItem>
                <ListItem>General Liability Insurance is in the case of a lawsuit for anything like carelessness, personal injury, property damage, libel, or slander, this helps defend your company and its assets</ListItem>
                <ListItem>Business Income Insurance, is if you cannot operate due to covered property damage, it helps you replace your lost revenue. This covers harm caused by fire, wind, or theft.</ListItem>
              </UnorderedList>
              <Text fontSize="lg" mb={"1.5em"}>
                You can add additional coverages to your Business Owner’s Policy (BOP) to help tailor coverage to your specific needs, like Workers' Compensation Insurance, Professional Liability Insurance, Commercial Auto Insurance, and Data Breach Insurance
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What’s the Advantage of a Business Owner’s Policy?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                It combines two essential needs of a business owner, business property insurance and business liability insurance. Business Owner's Policy (BOP) gives companies an opportunity to cut costs while obtaining comprehensive coverage for topics like:
              </Text>
              <UnorderedList>
                <ListItem>Theft</ListItem>
                <ListItem>Fire</ListItem>
                <ListItem>Lawsuits</ListItem>
                <ListItem>Loss of income</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Who Needs a Business Owners Policy?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                It is important that all business owners have business owner's policy (BOP). Your business is one of your biggest assets. You worked hard to build it, now make sure you protect it.
                <br />
                Your company may benefit from a BOP if:
              </Text>
              <UnorderedList>
                <ListItem>You have a physical location</ListItem>
                <ListItem>There is a possibility of getting sued</ListItem>
                <ListItem>You have an asset that could get stolen or damaged</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What types of businesses could benefit from a BOP?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                A business owner's insurance may be customized to your needs, whether you own an auto repair shop, a retail store, an accountancy firm, or many other small businesses. In the event that your company:
              </Text>
              <UnorderedList>
                <ListItem>It is located outside of your home</ListItem>
                <ListItem>Has fewer than 100 employees</ListItem>
                <ListItem>Has property or equipment you want to protect</ListItem>
              </UnorderedList>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Save Time and Money with Lia Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              If you need Business Owners Protection insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different valuable possessions insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your business!
              </Text>
              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default BusinessOwnersPolicyInsurance;
