import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Divider,
  Link,
  Button,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import AutoInsurancePic from "../../images/personal-insurance/CarInsurance.jpg";

function CommercialAutoInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");


  return (
    <Box bg={bgColor} minHeight="100%">
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"Auto Insurance"}
          footer={
            "We Get You The Auto Coverage You Need at an Affordable Price"
          }
          img={AutoInsurancePic}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What is Commercial Auto Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Commercial Auto Insurance is a type of business insurance that covers cars you own or use for your business. It protects you from financial loss due to accidents involving these vehicles and offers specific benefits to passengers who are hurt while riding in them.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What Does Commercial Auto Insurance Cover?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                It includes two types of physical damage coverage for your insured vehicles:
              </Text>
              <UnorderedList>
                <ListItem>Collision loss: damage from auto accidents.</ListItem>
                <ListItem>Comprehensive loss: damage from weather, theft, and other non-collision causes.</ListItem>
              </UnorderedList>
              <Text fontSize="lg" mb={"1.5em"}>
                It also includes two types of liability coverage:
              </Text>
              <UnorderedList>
                <ListItem>Bodily injury coverage: for accident-related injuries to others when you're at fault.</ListItem>
                <ListItem>Property damage coverage: from accident-related damage to someone else's property, such as a car or house, when you're at fault.</ListItem>
              </UnorderedList>
              <Text fontSize="lg" mb={"1.5em"}>
                The commercial car policy offers liability coverage for injuries and property damage caused to third parties by your use of the covered company vehicles and physical damage coverage for vehicles you own, lease, rent, or use in your business.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Do You Need Commercial Automobile Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                You should have commercial auto insurance if you own, lease, rent, or utilize automobiles for commercial purposes. Most states need it for privately owned vehicles. Additionally, it is mandated by federal law for companies that transport persons or products across state boundaries.
              </Text>
              <Text fontSize="lg" mb={"1.5em"}>
                You should also carry commercial auto coverage if you or your employees use vehicles for work-related tasks, such as:
              </Text>
              <UnorderedList>
                <ListItem>Driving clients to the airport</ListItem>
                <ListItem>Going on sales calls</ListItem>
                <ListItem>Taking packages to a shipper</ListItem>
                <ListItem>Shopping for office supplies</ListItem>
              </UnorderedList>
              <Text fontSize="lg" mb={"1.5em"}>
                You need commercial auto insurance for vehicles that:
              </Text>
              <UnorderedList>
                <ListItem>Are used for business</ListItem>
                <ListItem>Carry a commercial license plate or tag</ListItem>
                <ListItem>Are registered commercial.</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What are the Coverages Included in a Commercial Auto Insurance
                Policy?
              </Heading>
              <UnorderedList>
                <ListItem>Collision coverage </ListItem>
                <ListItem>Comprehensive coverage</ListItem>
                <ListItem>Liability coverage</ListItem>
                <ListItem>Uninsured or Underinsured Motorist coverage</ListItem>
                <ListItem>Personal Injury Protection</ListItem>
                <ListItem>Medical Payments coverage</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                How Does Commercial Auto Insurance Work?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                If you or a driver of your business vehicle make a claim under your insurance coverage and start a lawsuit against you or the driver, if your company vehicles cause an accident that results in injuries to them or damage to their property. Your liability insurance will cover costs to pay damages on the claim up to the policy limit. In addition to the policy limit, your liability insurance will cover the expenses of defending you or the driver of your company vehicle in a lawsuit arising from an accident.
              </Text>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Save Time and Money with Lia Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              If you need commercial auto insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different valuable possessions insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your car!
              </Text>
              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default CommercialAutoInsurance;
