import { Box, Heading, Text, Image, VStack, useBreakpointValue, Link } from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import TopHeaderBar from "../../components/Top";
import Footer from "../../components/Footer";
import GetAQuote from "../../components/GetAQuote";
import Pic1 from "../../images/blog/blog6image1.jpg";
import Pic2 from "../../images/blog/blog6image2.png";
import Pic3 from "../../images/blog/blog6image3.jpg";
import Pic4 from "../../images/blog/blog6image4.png";
import Pic5 from "../../images/blog/blog6image5.jpg";

const LandingPage6 = () => {
    const textWidth = useBreakpointValue({ base: "100%", md: "80%", lg: "50%" });
    const fontSize = useBreakpointValue({ base: "md", md: "lg" });
    const headingSize = useBreakpointValue({ base: "2xl", md: "3xl" });
    const subHeadingSize = useBreakpointValue({ base: "xl", md: "2xl" });

    return (
        <Box bg="gray.100" minHeight="100vh" fontFamily="Arial, sans-serif">
            <TopHeaderBar />
            <NavigationHeader />
            <VStack spacing={10} py={12} w="100%" px={[4, 8, 12]} align="center" bg="white" boxShadow="md" borderRadius="md">
                <Heading as="h1" fontSize={headingSize} color="blue.700" fontWeight="bold">
                    Understanding Home Insurance Coverages in New York
                </Heading>

                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    Homeowners in New York rely on their home insurance policies to protect their most valuable asset — their home. However, understanding what exactly is covered in a policy can sometimes be confusing. In this guide, we'll break down the essential coverages included in a standard home insurance policy, as well as additional optional coverages like equipment breakdown, water back-up, and utility/service line coverage.
                </Text>

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">Standard Coverages in a Home Insurance Policy</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    Generally, a homeowners policy is made up of at least 6 different parts of coverage, indicated on a declarations page by letters A through F, that step up to provide coverage on different parts of your home in different situations:
                </Text>

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">1. Dwelling Coverage (Coverage A)</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>What It Covers:</strong> Dwelling coverage protects the structure of your home, including the roof, walls, and any attached structures like a garage. It helps pay to repair or rebuild your home if it's damaged by covered perils such as fire, windstorms, hail, or vandalism.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Example:</strong> If a tree falls on your home during a storm and damages the roof, dwelling coverage would help pay for repairs.
                </Text>

                <Image src={Pic1} alt="Dwelling Coverage" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">2. Other Structures Coverage (Coverage B)</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>What It Covers:</strong> This covers structures that are separate from your main dwelling, such as fences, detached garages, sheds, or guest houses.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Example:</strong> If your detached garage is damaged by a fire, this part of your policy would cover the cost of rebuilding it.
                </Text>

                <Image src={Pic2} alt="Other Structures Coverage" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">3. Personal Property Coverage (Coverage C)</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>What It Covers:</strong> This part of your policy protects your personal belongings, including furniture, electronics, clothing, and appliances, from damage or theft.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Example:</strong> If your home is burglarized and your TV and laptop are stolen, personal property coverage would help replace those items.
                </Text>

                <Image src={Pic3} alt="Personal Property Coverage" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">4. Loss of Use (Coverage D)</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>What It Covers:</strong> Loss of use coverage helps cover the cost of living elsewhere temporarily if your home becomes uninhabitable due to a covered peril.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Example:</strong> If a fire forces you to leave your home for repairs, loss of use would cover the cost of staying in a hotel and any additional living expenses during that time.
                </Text>

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">5. Liability Protection (Coverage E)</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>What It Covers:</strong> Liability protection helps cover legal and medical expenses if you're found responsible for injuries or damages to someone else's property.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Example:</strong> If a visitor slips and falls on your icy sidewalk and sues you for their medical bills, liability coverage could help cover the cost of legal defense and any settlement.
                </Text>

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">6. Medical Payments (Coverage F)</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>What It Covers:</strong> Medical payments coverage helps pay for minor medical expenses if someone is injured on your property, regardless of who is at fault. This coverage is designed for small claims and helps avoid potential lawsuits.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Example:</strong> If a guest trips on your front steps and suffers a minor injury, like a sprained ankle, medical payments coverage can help cover their medical bills (such as doctor visits, X-rays, or minor treatments). Unlike liability coverage, it doesn't require proving fault, making it a convenient way to manage small accidents without escalating to legal claims.
                </Text>

                <Image src={Pic4} alt="Medical Payments Coverage" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">Optional Coverages for Additional Protection</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    In addition to the standard coverages, homeowners in New York can choose from a variety of optional coverages that offer extra protection for specific situations:
                </Text>

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">1. Equipment Breakdown Coverage</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>What It Covers:</strong> Equipment breakdown coverage helps protect against the cost of repairing or replacing major household systems and appliances that suffer mechanical or electrical failure.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Example:</strong> If your central air conditioning unit breaks down due to a mechanical issue, this coverage could help pay for its repair or replacement. Note that this does not cover general wear and tear but sudden breakdowns.
                </Text>

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">2. Water Back-Up Coverage</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>What It Covers:</strong> Water back-up coverage protects against damage caused by water backing up into your home from a sewer, drain, or sump pump.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Example:</strong> If your basement floods because of a backed-up sewer line, this coverage would help pay for water damage repairs, cleanup, and replacing any damaged belongings. Without this coverage, you would have to pay for these repairs out of pocket, as water back-up is not typically covered under standard homeowners insurance.
                </Text>

                <Image src={Pic5} alt="Water Back-Up Coverage" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">3. Utility/Service Line Coverage</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>What It Covers:</strong> This coverage protects against the high cost of repairing underground utility lines that may run from the street to your home, including water, electricity, gas, and sewer lines. These lines are typically the homeowner's responsibility.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Example:</strong> If a tree root damages your underground water line, service line coverage would help pay for the excavation and repair costs. Without this coverage, you'd be responsible for covering these expenses, which can be significant.
                </Text>

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">Why These Coverages Matter</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    Many homeowners assume that their standard policy covers all types of damages, but without optional coverages like water back-up, equipment breakdown, and service line coverage, you could be left with significant out-of-pocket expenses for specific types of incidents. For example, water damage from a backed-up drain can easily cost thousands of dollars to repair, and mechanical breakdowns of major appliances can be just as costly.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    By adding these optional coverages to your policy, you can protect yourself from the unexpected and reduce the financial burden that comes with home repairs and maintenance.
                </Text>

                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6" mt={6}>
                    Home insurance is an essential part of protecting your home and your finances. Understanding the different coverages, from dwelling and personal property to equipment breakdown and water back-up, helps ensure you're adequately covered for a wide range of potential risks. For homeowners in New York, especially those with aging infrastructure or valuable home systems, optional coverages like service line coverage can offer peace of mind when it comes to unexpected repair costs.
                </Text>

                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    To ensure you have the right protection in place, review your policy with an insurance professional, and consider adding the necessary optional coverages based on your home's needs. If you would like us to review your policy and provide you with a free quote on your home, please reach out to us at <Link href="tel:+15185793153" color="blue.500">(518) 579-3153</Link> or email us at <Link href="mailto:insurance@liagroup.com" color="blue.500">insurance@liagroup.com</Link>.
                </Text>
            </VStack>
            <Footer mb={9} />
            <GetAQuote />
        </Box>
    );
};

export default LandingPage6;