import { Box, Heading, Text, Image, VStack, useBreakpointValue, UnorderedList, ListItem } from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import TopHeaderBar from "../../components/Top";
import Footer from "../../components/Footer";
import GetAQuote from "../../components/GetAQuote";
import Pic1 from "../../images/blog/blog3image1.jpg";
import Pic2 from "../../images/blog/blog3image2.jpg";
import Pic3 from "../../images/blog/blog3image3.jpg";
import Pic4 from "../../images/blog/blog3image4.jpg";


const LandingPage3 = () => {
    const textWidth = useBreakpointValue({ base: "100%", md: "80%", lg: "50%" });
    const fontSize = useBreakpointValue({ base: "md", md: "lg" });
    const headingSize = useBreakpointValue({ base: "2xl", md: "3xl" });
    const subHeadingSize = useBreakpointValue({ base: "xl", md: "2xl" });

    return (
        <Box bg="gray.100" minHeight="100vh" fontFamily="Arial, sans-serif">
            <TopHeaderBar />
            <NavigationHeader />
            <VStack spacing={10} py={12} w="100%" px={[4, 8, 12]} align="center" bg="white" boxShadow="md" borderRadius="md">
                <Heading as="h1" fontSize={headingSize} color="blue.700" fontWeight="bold">Understanding Auto Insurance in New York: Your Complete Guide

                </Heading>

                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Auto insurance can feel like a maze, but it's crucial to know the ins and outs of your policy to stay protected on the road. Whether you're cruising through the streets of NYC or navigating the scenic highways upstate, having the right coverage can make all the difference in the event of an accident or unexpected mishap. In this comprehensive guide, we'll go over the main parts of an auto insurance policy in New York, explaining each coverage in simple terms and providing real-life examples to help you make informed decisions.
                </Text>

                <Image src={Pic1} alt="Liability Coverage" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">1. Liability Coverage</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    Liability coverage is your safety net if you're found responsible for causing an accident that injures someone else or damages their property. Injuries and property can get expensive fast, so we recommend 100/300/100 as the “standard” coverage limits, which are:
                    <UnorderedList mt={"2em"} mb={"2em"}>
                        <ListItem ListIcon="ul">$100,000 bodily injury liability per person
                        </ListItem>
                        <ListItem>$300,000 bodily injury liability per accident

                        </ListItem>
                        <ListItem>$100,000 property damage liability per accident

                        </ListItem>
                    </UnorderedList>





                    Example: You accidentally sideswipe another car while changing lanes, causing minor injuries to the driver and damaging their vehicle. Your liability coverage would step in to cover the other driver's medical bills and repair costs for their vehicle, up to your policy limits.
                </Text>

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">2. Personal Injury Protection (PIP)</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Personal Injury Protection, or PIP, covers medical expenses for you and your passengers if you're injured in an accident, regardless of fault. It can also help with other expenses like lost wages and funeral costs. There is supplemental coverage as well, Additional Personal Injury Protection (APIP) which may be (and generally is) included.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Example: You're rear-ended at a stop sign, resulting in whiplash and a trip to the emergency room. PIP would cover your medical bills and a portion of your lost income while you recover.
                </Text>

                <Image src={Pic2} alt="Uninsured Motorist Coverage" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">3. Uninsured/Underinsured Motorist Coverage</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Uninsured/Underinsured Motorist Coverage provides financial protection if you're in an accident with a driver who doesn't have insurance or doesn't have enough insurance to cover your expenses.

                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Example: You're hit by a driver who runs a red light and flees the scene. Later, it's discovered that the driver is uninsured. Without uninsured motorist coverage, you'd be responsible for your medical bills and vehicle repairs, but this coverage would step in to cover the costs.
                </Text>

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">4. Collision Coverage</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Collision coverage pays for damage to your vehicle in the event of an accident, regardless of fault.

                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Example: You skid on black ice and collide with a guardrail, causing significant damage to the front of your car. Collision coverage would cover the cost of repairs, minus your deductible.
                </Text>

                <Image src={Pic3} alt="Comprehensive Coverage" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">5. Comprehensive Coverage</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Comprehensive coverage protects your vehicle against non-collision events, such as theft, vandalism, fire, natural disasters, or hitting an animal.


                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Example: Your parked car is broken into, and your stereo system is stolen. Comprehensive coverage would help cover the cost of replacing the stolen items and repairing any damage to your vehicle, minus your deductible.


                </Text>

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">6. Rental Reimbursement Coverage:</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Rental Reimbursement Coverage helps pay for a rental car if your vehicle is in the shop for repairs after a covered accident.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Example: Your car is involved in a collision and needs extensive repairs. Rental reimbursement coverage would cover the cost of renting a car while yours is being repaired, up to the limits of your policy. Limits are typically $30-50 per day, up to 30 days.


                </Text>

                <Image src={Pic4} alt="Roadside Assistance and Towing" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />
                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">7. Roadside Assistance/Towing Coverage:</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Roadside Assistance/Towing Coverage provides assistance if your car breaks down or you're stranded on the side of the road.
                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Example: Your car's battery dies while you're out running errands. Roadside assistance coverage would send a tow truck to jump-start your car or tow it to a nearby mechanic, saving you time and hassle. It’s important to keep in mind this coverage should be used for emergencies and should not be considered a replacement for AAA.
                </Text>

                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6" mt={6}>
                Auto insurance in New York can be complex, but having the right coverage can offer peace of mind and financial protection when you need it most. By understanding each type of coverage and how it works, you can drive with confidence knowing you're prepared for whatever comes your way on the road. Be sure to review your policy regularly and make any necessary adjustments to ensure you have adequate protection for your needs – if you have questions or want to make some changes, give us a call or email today. Safe travels!
                </Text>
            </VStack>
            <Footer />
            <GetAQuote />
        </Box>
    );
};

export default LandingPage3;
