import React from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  VStack,
  useMediaQuery,
  Text
} from '@chakra-ui/react';

function GetAQuoteForm() {
  const smallScreen = useMediaQuery('(max-width: 800px)')
  return (
    <ChakraProvider>
      <Box width={"100%"} height={"1200px"}>
        <Flex
          height={"120%"}
          alignContent={"center"}
          flexDirection={"column"}
          bgGradient="linear(to-r, gray.100, gray.200)"
          py={16}
        >
          <VStack mx={"auto"} mb={"2em"} textAlign={"center"} p={6} borderRadius="lg" bg="white" spacing={6} w={smallScreen ? '90%' : '60%'} shadow="xl"
          >
            <Text fontSize="2xl" fontWeight="bold">Get Your Insurance Quote</Text>
            <Box
              width="100%"
              border="1px solid gray.200"
              borderRadius="lg"
              overflow={"hidden"}
            >
              <iframe
                src="https://www.agentinsure.com/compare/auto-insurance-home-insurance/liagsllc/quote.aspx"
                title="Insurance Quote"
                style={{ width: '100%', height: '800px', border: 'none' }}
              />
            </Box>
          </VStack>
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default GetAQuoteForm;



/*

import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  NumberInput,
  NumberInputField,
  Textarea,
  Button,
  Select,
} from "@chakra-ui/react";

function GetAQuoteForm({ borderRadius, width }) {
  const [insuranceType, setInsuranceType] = useState("");
  const [specificType, setSpecificType] = useState("");

  const [isBusiness, setIsBusiness] = useState(false);
  const [state, setState] = useState("");
  const [lineOfBusiness, setLineOfBusiness] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    // Process form data and send an email here
  };

  const personalOptions = [
    "Home",
    "Auto",
    "Motorcycle",
    "Renters",
    "Off-road",
    "RV",
    "Snowmobile",
    "Watercraft",
    "Umbrella",
    "Valuable Possession",
  ];

  const stateOptions = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
  const businessOptions = ["Home", "Auto", "Home & Auto", "Other"];

  const handleChange = (e) => {
    setInsuranceType(e.target.value);
    setSpecificType("");
  };

  const handleSpecificChange = (e) => {
    setSpecificType(e.target.value);
  };

  const handleBusinessChange = (e) => {
    setIsBusiness(e.target.checked);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleBusinessLineChange = (e) => {
    setLineOfBusiness(e.target.value);
  };

  return (
    <ChakraProvider>
      <Box height={"100%"} width={"100%"}>
        <Flex
          borderRadius={borderRadius || "0"}
          width={"100%"}
          alignContent={"center"}
          flexDirection={"column"}
          bgGradient="linear(to-r, gray.100, gray.200)"
          py={16}
        >
          <VStack mx={"auto"} mb={"2em"} textAlign={"center"} spacing={6}>
            <Heading
              as={"h1"}
              fontSize={{ base: "3xl", md: "4xl" }}
              fontWeight="bold"
              color={"gray.700"}
            >
              Get A Quote Today
            </Heading>
            <Heading
              as={"h2"}
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="medium"
              color={"gray.600"}
            >
              Getting Started
            </Heading>
            <Text
              maxW={{ base: "80vw", md: "40vw" }}
              fontSize={"lg"}
              fontWeight="medium"
              lineHeight={"tall"}
              color={"gray.500"}
            >
              We follow the highest industry standards to safeguard the
              confidentiality of your personal information and secure the
              transmission of your information from your computer.
            </Text>
          </VStack>
          <Box
            width={width || { base: "80%", md: "40%" }}
            mx={"auto"}
            py={8}
            backgroundColor="white"
            borderRadius="lg"
            boxShadow="lg"
            px={8}
          >
            <form onSubmit={onSubmit}>
              <VStack spacing={6} mt={4}>
                <FormControl id="isBusiness">
                  <Checkbox
                    required
                    colorScheme="green"
                    onChange={handleBusinessChange}
                  >
                    Are you a business?
                  </Checkbox>
                </FormControl>
                <FormControl id="firstName" isRequired>
                  <FormLabel color={"black"}>First Name</FormLabel>
                  <Input
                    type="text"
                    required
                    bg="white"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{
                      borderColor: "gray.500",
                      boxShadow: "0 0 0 1px teal.500",
                    }}
                  />
                </FormControl>
                <FormControl id="lastName" isRequired>
                  <FormLabel color={"black"}>Last Name</FormLabel>
                  <Input
                    type="text"
                    required
                    bg="white"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{
                      borderColor: "gray.500",
                      boxShadow: "0 0 0 1px teal.500",
                    }}
                  />
                </FormControl>
                <FormControl id="phoneNumber" isRequired>
                  <FormLabel color={"black"}>Phone Number</FormLabel>
                  <Input
                    type="tel"
                    required
                    bg="white"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{
                      borderColor: "gray.500",
                      boxShadow: "0 0 0 1px teal.500",
                    }}
                  />
                </FormControl>
                <FormControl id="email" isRequired>
                  <FormLabel color={"black"}>Email</FormLabel>
                  <Input
                    type="email"
                    required
                    bg="white"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{
                      borderColor: "gray.500",
                      boxShadow: "0 0 0 1px teal.500",
                    }}
                  />
                </FormControl>
                <FormControl id="address" isRequired>
                  <FormLabel>Address</FormLabel>
                  <Input type="text" />
                </FormControl>
                <FormControl id="aptNumber">
                  <FormLabel>Apt #</FormLabel>
                  <NumberInput>
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
                <FormControl id="city" isRequired>
                  <FormLabel>City</FormLabel>
                  <Input type="text" />
                </FormControl>
                <FormControl id="state" isRequired>
                  <FormLabel>State</FormLabel>
                  <Select value={state} onChange={handleStateChange}>
                    <option value="">Select...</option>
                    {stateOptions.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {insuranceType && (
                  <FormControl id="specificType">
                    <FormLabel color={"black"}>
                      What type of insurance?
                    </FormLabel>
                    <Select
                      value={specificType}
                      onChange={handleSpecificChange}
                    >
                      <option value="">Select...</option>
                      {(insuranceType === "Personal"
                        ? personalOptions
                        : businessOptions
                      ).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <FormControl id="zipCode" isRequired>
                  <FormLabel>Zip code</FormLabel>
                  <NumberInput>
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
                <FormControl id="lineOfBusiness" isRequired>
                  <FormLabel>Line of Business</FormLabel>
                  <Select
                    value={lineOfBusiness}
                    onChange={handleBusinessLineChange}
                  >
                    <option value="">Select...</option>
                    {businessOptions.map((business) => (
                      <option key={business} value={business}>
                        {business}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl id="comments">
                  <FormLabel color={"black"}>Comments</FormLabel>
                  <Textarea
                    required
                    bg="white"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{
                      borderColor: "gray.500",
                      boxShadow: "0 0 0 1px teal.500",
                    }}
                  />
                </FormControl>
                <Button
                  type="submit"
                  color={"white"}
                  bg={"purple.700"}
                  _hover={{ bg: "purple.800" }}
                  size="lg"
                >
                  Continue
                </Button>
              </VStack>
            </form>
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default GetAQuoteForm;



*/