import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Divider,
  HStack,
  Button,
  Flex,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import Footer from './components/Footer';
import GetAQuote from './components/GetAQuote';
import TopHeaderBar from './components/Top';
import NavigationHeader from './components/NavigationHeader';
import { Link } from 'react-router-dom';

const dummyPosts = [
  {
    id: 6,
    title: 'Understanding Home Insurance Coverages in New York',
    content: 'Homeowners in New York rely on their home insurance policies to protect their most valuable asset — their home...',
    date: 'September 24, 2024',
    link: "/blog/6"
  },
  {
    id: 5,
    title: 'Getting Your Home Ready Before a Vacation: A Comprehensive Checklist',
    content: 'Planning for a vacation involves more than just packing your bags and booking your travel...',
    date: 'July 23, 2024',
    link: "/blog/5"
  },
  {
    id: 4,
    title: 'Water Backup vs. Flood Coverage: What`s the Difference?',
    content: 'Water damage can be a nightmare for homeowners...',
    date: 'April 30, 2024',
    link: "/blog/4"
  },
  {
    id: 3,
    title: 'Understanding Auto Insurance in New York: Your Complete Guide',
    content: 'Auto insurance can feel like a maze, but it`s crucial to know the ins and outs of your policy to stay protected on the road...',
    date: 'April 22, 2024',
    link: "/blog/3"
  },
  {
    id: 2,
    title: 'Managing Rising Insurance Costs: What can you do to save?',
    content: 'In this current landscape of escalating rates, primarily influenced by factors beyond our immediate control...',
    date: 'August 26, 2023',
    link: "/blog/2"
  },
  {
    id: 1,
    title: 'Why Are Insurance Rates Increasing? Understanding the Factors',
    content: 'Navigating the world of insurance can feel intricate, overwhelming, and sometimes shrouded in mystery...',
    date: 'August 25, 2023',
    link: "/blog/1"
  },
];

const BlogPage = () => {
  return (
    <Box height="100%" pb={10}>
        <TopHeaderBar/>
        <NavigationHeader/>
    <VStack maxW="container.xl" height={"100%"} w="80%" m="auto" align="center" bg="white" boxShadow="md" borderRadius="md" p={8} >
      <Heading as="h1" size="2xl" my={6}>
        Blog
      </Heading>
      <Flex direction={{ base: 'column', md: 'row' }} >
        <VStack spacing={8} align="stretch" width={{ md: '75%' }} >
          {dummyPosts.map((post) => (
            <Box key={post.id} p={5} shadow="md" borderWidth="1px">
              <Heading fontSize="xl">{post.title}</Heading>
              <HStack my={2}>
                <CalendarIcon />
                <Text>{post.date}</Text>
              </HStack>
              <Divider my={4} />
              <Text mt={4}>{post.content}</Text>
              <Link to={post.link}>
              <Button mt={4} colorScheme="teal">
                Read more
              </Button>
              </Link>
            </Box>
          ))}
        </VStack>
        <Box ml={{ md: 6 }} mt={{ base: 6, md: 0 }} width={{ md: '25%' }}>
          <VStack spacing={4} align="stretch">
            <Box p={4} shadow="md" borderWidth="1px">
              <Heading size="md">Recent Posts</Heading>
              <Divider my={4} />
              <VStack align="start">
                <Link to="/blog/1" ><Text _hover={{textDecoration: "underline"}} cursor="pointer">Why Are Insurance Rates Increasing? Understanding The Factors</Text></Link>
                <Link to="/blog/2"><Text _hover={{textDecoration: "underline"}} cursor="pointer">Managing Rising Insurance Costs: What can you do to save?</Text></Link>
              </VStack>
            </Box>
          </VStack>
        </Box>
      </Flex>
    </VStack>
    <GetAQuote/>
    <Footer/>
    </Box>
  );
};

export default BlogPage;