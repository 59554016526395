import { React, useState, } from "react";
import TopHeaderBar from "./components/Top";
import FooterBar from "./components/GetAQuote";
import Footer from "./components/Footer"
import NavigationHeader from "./components/NavigationHeader";
import { Box, ChakraProvider } from "@chakra-ui/react"
import Banner from "./pages/Personal-Insurance/Banner"
import stock from "./images/business-insurance/stock.jpeg"
import { Link } from 'react-router-dom';

import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, VStack } from '@chakra-ui/react';

import ReactGA from "react-ga4"

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)

const getAQuoteFull = () => {
  const event = ReactGA.event('quote_full', {
    category: 'Clicks',
    action: 'Get A Quote Full',
    label: '/quote-full',
  });
  console.log(event)

};
const getAQuoteQuick = () => {
  const event = ReactGA.event('quote_quick', {
    category: 'Clicks',
    action: 'Get A Quote Quick',
    label: '/quote-quick',
  });
  console.log(event)

};
// const getAQuoteTool = () => {
//   const event = ReactGA.event('quote_tool', {
//     category: 'Clicks',
//     action: 'Get A Quote Tool',
//     label: '/quote-tool',
//   });
//   console.log(event)

// };

function QuoteOptionsModal({ isOpen, onOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onOpen={onOpen} onClose={onClose} alignItems="center" justifyContent="center">
      <ModalOverlay h={"100%"} w={"100%"}/>
      <ModalContent>
        <ModalHeader>Select an option</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Link onClick={getAQuoteQuick} to="/quote-quick"><Button>Learn More - Quick Quote</Button></Link>
            <Link onClick={getAQuoteFull} to="/quote-full"><Button>Full Quote Form</Button></Link>
            <Link onClick={getAQuoteQuick} to="/quote-quick"><Button>Easy Quote Tool</Button></Link>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}


function GetAQuote() {
    const [formType, setFormType] = useState(null); // To track which form the user wants
    const [isModalOpen, setModalOpen] = useState(true); // Initially true to display the modal when page loads
    
    return (
        <ChakraProvider>
          <Box>
            <TopHeaderBar/>
            <NavigationHeader/>
            <Banner header={"Get a Quote From Lia Insurance Today!"} footer={"Affordable Insurance Rates in NY, CT, and MA."} img={stock} fontSize={"1.5em"} />
            
            {/* Show the modal */}
            <QuoteOptionsModal isOpen={isModalOpen} onOpen={() => setModalOpen(true)} onClose={() => setModalOpen(false)} setFormType={setFormType} />
            
            {/* Conditionally render the form */}
            {
              formType === "quickQuote"
            }
            {
              formType === "fullQuote"
            }
            {
              formType === "easyQuote"
            }
            
            <Footer/>
            <FooterBar />
          </Box>
        </ChakraProvider>
      );
}

export default GetAQuote;



// function GetAQuote() {
//     const [formType, setFormType] = useState(null); // To track which form the user wants
//     const [isModalOpen, setModalOpen] = useState(true); // Initially true to display the modal when page loads
    
//     return (
//         <ChakraProvider>
//           <Box>
//             <TopHeaderBar/>
//             <NavigationHeader/>
//             <Banner header={"Get a Quote From Lia Insurance Today!"} footer={"Affordable Insurance Rates in NY, CT, and MA."} img={stock} fontSize={"1.5em"} />
            
//             {/* Show the modal */}
//             <QuoteOptionsModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} setFormType={setFormType} />
            
//             {/* Conditionally render the form */}
//             {
//               formType === "quickQuote" && <QuickQuoteForm />
//             }
//             {
//               formType === "fullQuote" && <GetAQuoteForm />
//             }
//             {
//               formType === "easyQuote" && <EasyQuoteTool />
//             }
            
//             <Footer/>
//             <FooterBar />
//           </Box>
//         </ChakraProvider>
//       );
// }

// export default GetAQuote;






/*

function GetAQuote() {
    return (
        <ChakraProvider>
          <Box>
            <TopHeaderBar/>
            <NavigationHeader/>
            <Banner header={"Get a Quote From Lia Insurance Today!"} footer={"Affordable Insurance Rates in NY, CT, and MA."} img={stock} fontSize={"1.5em"} />
            <GetAQuoteForm/>
            <Footer/>
            <FooterBar />
          </Box>
        </ChakraProvider>
      );
      
}

export default GetAQuote;

*/