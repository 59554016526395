import {
  Box,
  Heading,
  Text,
  Button,
  UnorderedList,
  ListItem,
  Divider,
  VStack,
  useColorModeValue,
  Container
} from "@chakra-ui/react";
import { Link } from "react-router-dom"
import NavigationHeader from "../../components/NavigationHeader";
import FooterBar from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "./Banner";
import HomeInsurancePic from "../../images/personal-insurance/HomeInsurance.jpg"

function HomeInsurance({ heading, description, body, covered, notCovered, footer }) {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");

  return (
    <Box bg={bgColor} minHeight="100%">
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner header={"Home Insurance"} footer={"Protect your most valuable asset"} img={HomeInsurancePic} />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                {heading.primary} insurance in NY, Connecticut, and Massachusetts.
              </Heading>

              <Text fontSize="lg" mb={"1.5em"}>
                {description}
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h3" size="lg" mb={4}>
                What does a {heading.secondary} policy cover?
              </Heading>

              <UnorderedList spacing={3} mb={6}>
                {covered.map((type, index) => {
                  return <ListItem key={index}>{type}</ListItem>;
                })}
              </UnorderedList>

              <Text fontSize="lg" mb={6}>
                {body}
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h3" size="lg" mb={4}>
                What is not covered by {heading.secondary} insurance?
              </Heading>

              <UnorderedList spacing={3} mb={6}>
                {notCovered.map((type, index) => {
                  return <ListItem key={index}>{type}</ListItem>;
                })}
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h3" size="lg" mb={4}>
                Save Time and Money with {heading.secondary} insurance
              </Heading>

              <Text fontSize="lg" mb={6}>
                {footer}
              </Text>

              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <FooterBar />
    </Box>
  );
}

export default HomeInsurance;