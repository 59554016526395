import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Divider,
  Button,
  Link,
  UnorderedList,
  ListItem
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import WorkersComp from "../../images/business-insurance/workers.jpg";

function WorkersCompensationInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box bg={bgColor} minHeight="100%">
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"Workers' Compensation Insurance"}
          footer={"Affordable Worker's Compensation Insurance"}
          img={WorkersComp}
          fontSize={"1.5em"}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What is Workers' Compensation Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Workers' compensation insurance offers medical and salary benefits for those who are hurt or ill at work. It also pays death benefits to families of employees who are killed on the job. Workers ' compensation is seen as a form of social insurance because it is based on a social contract between management and labor. By obtaining workers' compensation insurance, company owners are protected against civil lawsuits from employees who have workplace injuries.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Who is Required to Carry Workers' Compensation Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Every state mandates that companies hiring non-owner employees acquire workers' compensation insurance for such employees. Businesses that don't have workers' compensation insurance risk severe and expensive consequences, such as paying claims out of pocket, paying penalties, maybe going to jail, and possibly losing their ability to operate in the state.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What Types of Injuries Are Covered by Workers' Comp?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Workers' compensation can aid if an employee is hurt while working for you or while performing duties on your behalf. Workers' compensation, for instance, might assist in covering their medical expenses if they are injured while traveling to deliver something to a client.
              </Text>
              <UnorderedList>
                <ListItem>Providing benefits to your workers who suffer diseases or injuries at work</ListItem>
                <ListItem>Reducing the company's legal responsibility for work-related illnesses or injuries</ListItem>
                <ListItem>Ensuring that you adhere to the workers' compensation regulations in your state</ListItem>
                <ListItem>Assisting disabled workers in returning to their previous or new job</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                How Does Workers' Compensation Protect My Business?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Workers' compensation insurance helps protect your business by:
              </Text>
              <UnorderedList>
                <ListItem>Providing benefits to your workers who suffer diseases or injuries at work</ListItem>
                <ListItem>Reducing the company's legal responsibility for work-related illnesses or injuries</ListItem>
                <ListItem>Ensuring that you adhere to the workers' compensation regulations in your state</ListItem>
                <ListItem>Assisting disabled workers in returning to their previous or new job</ListItem>
              </UnorderedList>
              
            </Box>
            <Box
                p={6}
                bg={boxBgColor}
                boxShadow={boxShadow}
                borderRadius="lg"
                mb={10}
              >
                <Heading as="h2" size="xl" mb={6}>
                  Save Time and Money with Lia Insurance
                </Heading>
                <Text fontSize="lg" mb={"1.5em"}>
                If you need Worker's Compensation insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different Worker's Compensation insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your business!
                </Text>
                <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
              </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default WorkersCompensationInsurance;
