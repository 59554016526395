import { Box, Heading, Text, Link, Image, VStack, UnorderedList, ListItem, useBreakpointValue } from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import TopHeaderBar from "../../components/Top";
import Footer from "../../components/Footer";
import GetAQuote from "../../components/GetAQuote";
import Picture1 from "../../images/blog/Picture1.png";
import Picture2 from "../../images/blog/Picture2.png";

const LandingPage1 = () => {
  const textWidth = useBreakpointValue({ base: "100%", md: "80%", lg: "50%" });
  const fontSize = useBreakpointValue({ base: "md", md: "lg" });

  return (
    <Box bg="gray.100" minHeight="100vh" fontFamily="Arial, sans-serif">
      <TopHeaderBar />
      <NavigationHeader />
      <VStack spacing={10} py={12} w="100%" px={[4, 8, 12]} align="center" bg="white" boxShadow="md" borderRadius="md">
        <Heading as="h1" fontSize={["2xl", "3xl"]} color="blue.700" fontWeight="bold">
          Why Are Insurance Rates Increasing? Understanding the Factors
        </Heading>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Insurance can seem complicated, overwhelming, and sometimes a bit of a mystery, so we’ll try to keep things as simple as possible.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Companies use every bit of data they can to make projections on the likelihood and cost of a loss. From basic things you’d assume like:
          <UnorderedList spacing={2} mt={2}>
            <ListItem>Your age</ListItem>
            <ListItem>Driving history</ListItem>
            <ListItem>Where you live</ListItem>
          </UnorderedList>
          <Text py={4}>to things, you may not consider, like:</Text>
          <UnorderedList spacing={2} mt={2}>
            <ListItem>Weather patterns</ListItem>
            <ListItem>Supply chain</ListItem>
            <ListItem>Cost of materials and labor</ListItem>
          </UnorderedList>
          They then use these factors, among several others, to calculate rates based on what they think it would cost to make you whole in the event of a loss – like a roof being damaged in a hailstorm, or a vehicle being totaled in an accident.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          The unfortunate situation we find ourselves in now is lingering issues from the pandemic, compounded by trends that had already been happening. Supply chains remain tight and lagging, so in addition to things taking longer to get, they are more expensive. Technology in new vehicles continues to advance, increasing the cost of those vehicles and their repairs. As the cost of new cars rises, more people shop for used ones, which causes a lack of inventory and higher costs for used cars.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Supply chain issues driving the shortage of car parts and equipment have caused a 22.3% increase in the past 2 years, while the overall cost to maintain and repair a vehicle is up 18.4% in the same period. The same issues have depleted the supply of new and used cars since the pandemic, and inventories haven’t recovered, causing the cost of new cars to rise by 20% and used cars by 37% since 2020.
        </Text>

        <Box mt={6} mb={6} borderRadius="md" overflow="hidden">
          <Text w={textWidth} fontSize={fontSize} fontWeight="medium" color="gray.700" mb={2}>Table 1:</Text>
          <Image src={Picture1} alt="Table 1 showing car market data" boxShadow="sm" maxW="100%" />
        </Box>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          The same issues have also had an impact on the home market, and homeowners insurance. Inventories are low, driving up costs to purchase homes, as well as an increase in the cost of materials – in 2022, the cost of building materials rose by 4.7%, with large price increases on things like Asphalt Shingles (16.2%), Concrete Blocks (18.5%), and Drywall (20.4%). The cost of materials isn’t the only thing that has been impacted, home builders are also facing a shortage of nearly 300,000 skilled laborers.
        </Text>

        <Box mt={6} mb={6} borderRadius="md" overflow="hidden">
          <Text w={textWidth} fontSize={fontSize} fontWeight="medium" color="gray.700" mb={2}>Table 2:</Text>
          <Image src={Picture2} alt="Table 2 showing housing market data" boxShadow="sm" maxW="100%" />
        </Box>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          One other factor contributing to the rising insurance costs is irregular weather patterns and unexpected catastrophes like tornados/hurricanes, flooding, and wildfires. As the events have increased in frequency and severity, the losses have grown and increased the risk for all property insurance.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Since insurance coverages are based on what it would cost to reconstruct your home, all of the material costs, the increased cost of labor, and the unpredictability of significant weather events have had an impact on insurance and your premium.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          While we expect some of these factors to improve, like supply chains and labor, other trends, like vehicle technology and erratic weather, are likely here to stay. Fortunately, there are some things that you can do which you can read about
          <Link href="/path-to-blog2" color="blue.600" fontWeight="medium" _hover={{ textDecoration: 'underline' }} mt={2}> here.</Link>
        </Text>
      </VStack>
      <Footer />
      <GetAQuote />
    </Box>
  );
};

export default LandingPage1;





































// import { Box, Heading, Text, Link, Image, VStack, UnorderedList, ListItem } from "@chakra-ui/react";
// import NavigationHeader from "../../components/NavigationHeader";
// import TopHeaderBar from "../../components/Top";
// import Footer from "../../components/Footer";
// import GetAQuote from "../../components/GetAQuote";
// import Picture1 from "../../images/blog/Picture1.png"
// import Picture2 from "../../images/blog/Picture2.png"

// const LandingPage1 = () => {
//   return (
//     <Box bg="gray.100" minHeight="100vh" fontFamily="Arial, sans-serif">
//       <TopHeaderBar />
//       <NavigationHeader />
//       <VStack spacing={10} py={12} w="80%" m="auto" align="center" bg="white" boxShadow="md" borderRadius="md" p={8}>
//         <Heading as="h1" fontSize="3xl" color="blue.700" fontWeight="bold">Why Are Insurance Rates Increasing? Understanding the Factors</Heading>
        
//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//         Insurance can seem complicated, overwhelming, and sometimes a bit of a mystery, so we’ll try to keep things as simple as possible.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//         Companies use every bit of data they can to make projections on the likelihood and cost of a loss. From basic things you’d assume like:
//           <UnorderedList spacing={2} mt={2}>
//             <ListItem>Your age</ListItem>
//             <ListItem>Driving history</ListItem>
//             <ListItem>Where you live</ListItem>
//           </UnorderedList>
//           <Text py={4}>to things, you may not consider, like:</Text>
//           <UnorderedList spacing={2} mt={2}>
//             <ListItem>Weather patterns</ListItem>
//             <ListItem>Supply chain</ListItem>
//             <ListItem>Cost of materials and labor</ListItem>
//           </UnorderedList>
//           They then use these factors, among several others, to calculate rates based on what they think it would cost to make you whole in the event of a loss – like a roof being damaged in a hailstorm, or a vehicle being totaled in an accident.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//         The unfortunate situation we find ourselves in now is lingering issues from the pandemic, compounded by trends that had already been happening. Supply chains remain tight and lagging, so in addition to things taking longer to get, they are more expensive. Technology in new vehicles continues to advance, increasing the cost of those vehicles and their repairs. As the cost of new cars rises, more people shop for used ones, which causes a lack of inventory and higher costs for used cars.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//         Supply chain issues driving the shortage of car parts and equipment have caused a 22.3% increase in the past 2 years, while the overall cost to maintain and repair a vehicle is up 18.4% in the same period. The same issues have depleted the supply of new and used cars since the pandemic, and inventories haven’t recovered, causing the cost of new cars to rise by 20% and used cars by 37% since 2020
//         </Text>

//         <Box mt={6} mb={6} borderRadius="md" overflow="hidden">
//           <Text w={"50%"} fontSize="lg" fontWeight="medium" color="gray.700" mb={2}>Table 1:</Text>
//           <Image src={Picture1} alt="Table 1 showing car market data" boxShadow="sm" />
//         </Box>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//         The same issues have also had an impact on the home market, and homeowners insurance. Inventories are low, driving up costs to purchase homes, as well as an increase in the cost of materials – in 2022, the cost of building materials rose by 4.7%, with large price increases on things like Asphalt Shingles (16.2%), Concrete Blocks (18.5%), and Drywall (20.4%). The cost of materials isn’t the only thing that has been impacted, home builders are also facing a shortage of nearly 300,000 skilled laborers
//         </Text>

//         <Box mt={6} mb={6} borderRadius="md" overflow="hidden">
//           <Text w={"50%"} fontSize="lg" fontWeight="medium" color="gray.700" mb={2}>Table 2:</Text>
//           <Image src={Picture2} alt="Table 2 showing housing market data" boxShadow="sm" />
//         </Box>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//         One other factor contributing to the rising insurance costs is irregular weather patterns and unexpected catastrophes like tornados/hurricanes, flooding, and wildfires. As the events have increased in frequency and severity, the losses have grown and increased the risk for all property insurance.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//         Since insurance coverages are based on what it would cost to reconstruct your home, all of the material costs, the increased cost of labor, and the unpredictability of significant weather events have had an impact on insurance and your premium.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//         While we expect some of these factors to improve, like supply chains and labor, other trends, like vehicle technology and erratic weather, are likely here to stay. Fortunately, there are some things that you can do which you can read about
//           <Link href="/path-to-blog2" color="blue.600" fontWeight="medium" _hover={{ textDecoration: 'underline' }} mt={2}> here.</Link>
//         </Text>
//       </VStack>
//       <Footer />
//       <GetAQuote />
//     </Box>
//   );
// };

// export default LandingPage1;



  