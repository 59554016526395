import { useState } from 'react';
import {
    Box,
    VStack,
    Heading,
    Text,
    SimpleGrid,
    useColorModeValue,
    Link,
    Divider,
    ThemeProvider,
    useMediaQuery

} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { AiOutlineHome, AiOutlineCar, } from 'react-icons/ai'
import { RiMotorbikeLine, RiBus2Line } from 'react-icons/ri'
import { IoIosBoat, IoIosUmbrella } from 'react-icons/io'
import { SiAlltrails } from 'react-icons/si'
import { SlDiamond } from 'react-icons/sl'
import { TbReportMoney } from 'react-icons/tb'
import NavigationHeader from './components/NavigationHeader';
import FooterBar from './components/GetAQuote';
import theme from "./theme"
import TopHeaderBar from './components/Top';
import Banner from './pages/Personal-Insurance/Banner';
import test from './images/misc/test.png'
import BoatInsurance from "./images/personal-insurance/BoatInsurance.jpg"
import CarInsurance from "./images/personal-insurance/CarInsurance.jpg"
import HomeInsurance from "./images/personal-insurance/HomeInsurance.jpg"
import MotorcycleInsurance from "./images/personal-insurance/MotorcycleInsurance.jpg"
import OffroadInsurance from "./images/personal-insurance/OffroadInsurance.jpg"
import RentersInsurance from "./images/personal-insurance/RentersInsurance.jpg"
import RVInsurance from "./images/personal-insurance/RVInsurance.jpg"
import UmbrellaInsurance from "./images/personal-insurance/UmbrellaInsurance.jpg"
import ValuablePossessionInsurance from "./images/personal-insurance/ValuablePossessionInsurance.jpg"
import Footer from './components/Footer';

const MotionBox = motion(Box);

// Create a reusable insurance card component
const InsuranceCard = ({ icon: Icon, title, description, link, backgroundImage }) => {
    const bg = useColorModeValue('#BC3B4A', 'gray.700');

    const [isHovered, setIsHovered] = useState(false);
    const [isSmallScreen] = useMediaQuery('(max-width: 800px)');


    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const backgroundVariants = {
        rest: { opacity: 0 },
        hover: { opacity: 0.85 }
    };

    return (
        <ThemeProvider theme={theme}>
            <MotionBox
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                transition={{ duration: 0.2 }}
                p={6}
                borderRadius="lg"
                borderWidth="1px"
                borderColor="gray.200"
                boxShadow="md"
                textAlign="center"
                fontFamily={"body"}
                position="relative"
                overflow="hidden"
                bgImage={`url(${backgroundImage})`}
                bgSize="cover"
                bgPosition="center"
            >
                <MotionBox
                    variants={backgroundVariants}
                    animate={isHovered ? "hover" : "rest"}
                    transition={{ type: "spring", damping: 20, stiffness: 300 }}
                    bg={bg}
                    color="white"
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    zIndex="0"
                />

                <Box minHeight={isSmallScreen ? "0" : "220px"} position="relative" zIndex="0" color="white">
                    <Link href={`/insurance/personal/${link}`}>
                        <Box fontSize={"5xl"} mb={4} display={"flex"} justifyContent={"center"} mx={"auto"}>
                            <Icon />
                        </Box>
                    
                    <Heading as="h3" size="md" mb={2}>
                        {title}
                    </Heading>
                    <AnimatePresence>
                        {isHovered && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <Text>{description}</Text>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    </Link>
                </Box>
            </MotionBox>
        </ThemeProvider>
    );
};



const PersonalInsurance = () => {
    const textColor = useColorModeValue('gray.700', 'gray.300');
    const dividerColor = useColorModeValue('gray.200', 'gray.700');
    // Add your insurance data here
    const insuranceData = [
        {
            icon: AiOutlineHome,
            title: "Home Insurance",
            description: "We'll make a plan to protect your home and find the right insurance coverage for you and your family.",
            link: "home",
            bg: HomeInsurance
        },
        {
            icon: AiOutlineCar,
            title: "Auto Insurance",
            description: " Your car could be exposed to potential loss that might be out of your control. We can find the right coverage for you.",
            link: "auto",
            bg: CarInsurance
        },
        {
            icon: RiMotorbikeLine,
            title: "Motorcycle Insurance",
            description: "Let us help protect your motorcycle. We have affordable motorcycle insurance policies to keep you on the open road.",
            link: "motorcycle",
            bg: MotorcycleInsurance
        },
        {
            icon: TbReportMoney,
            title: "Renters Insurance",
            description: "If you are renting or leasing you often need a policy to protect your possessions inside the residence. We will help protect your personal property.",
            link: "renters",
            bg: RentersInsurance
        },
        {
            icon: SiAlltrails,
            title: "Off-Road Insurance",
            description: "We provide protection for your off-road vehicle, so you can hit the trails insured.",
            link: "offroad",
            bg: OffroadInsurance
        },
        {
            icon: RiBus2Line,
            title: "RV Insurance",
            description: "Have peace of mind when using your RV, whether you use it all year or only parts of the year, we can help you protect it.",
            link: "rv",
            bg: RVInsurance
        },
        {
            icon: IoIosBoat,
            title: "Watercraft Insurance",
            description: "Leave your worries on dry land and enjoy your time on the water.",
            link: "boat",
            bg: BoatInsurance
        },
        {
            icon: IoIosUmbrella,
            title: "Umbrella Insurance",
            description: "This type of insurance helps protect your financial future by providing additional coverage to your homeowners or auto policies in case or a major insurance claim or lawsuit.",
            link: "umbrella",
            bg: UmbrellaInsurance
        },
        {
            icon: SlDiamond,
            title: "Valuable Possession Insurance",
            description: "Accidents happen, let us protect you from lost diamonds or other valuable possessions from theft, an accident or a natural disaster.",
            link: "valuable-possessions",
            bg: ValuablePossessionInsurance
        },
    ]


    return (
        
            <Box width={"100%"} bg="gray.50" fontFamily={"body"}>
                <TopHeaderBar />
                <NavigationHeader />
                <Banner img={test} header={"Personal Insurance"} footer={"We offer personal insurance in New York, Connecticut and Massachusetts."} />
                <VStack spacing={8} alignItems="center" mx="auto" maxW="container.lg" mt={4} mb={20}>
                    <Divider borderColor={dividerColor} mb={4} />
                    <Text color={textColor} fontSize={{ base: 'lg', md: 'xl' }} textAlign="center">
                        As a local independent insurance agency, Lia Insurance Agency clients'
                        best interests are our best interests. As your local insurance agency,
                        it's our responsibility to provide you the best personalized service
                        and coverage at the best possible price. We specialize in homeowners,
                        renters, business, umbrella, motorcycle, and automotive insurance.
                    </Text>
                    <Divider borderColor={dividerColor} mb={4} />

                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full">
                        {insuranceData.map((insurance, index) => (
                            <InsuranceCard
                                key={index}
                                icon={insurance.icon}
                                title={insurance.title}
                                description={insurance.description}
                                link={insurance.link}
                                backgroundImage={insurance.bg}
                            />
                        ))}
                    </SimpleGrid>
                </VStack>
                <FooterBar />
                <Footer mb={10}/>
            </Box>
       
    );
};

export default PersonalInsurance;
