import {
    Box,
    Heading,
    Text,
    Button,
    UnorderedList,
    ListItem,
    Divider,
    VStack,
    useColorModeValue,
    Container,
} from "@chakra-ui/react";
import { Link } from "react-router-dom"
import NavigationHeader from "../../components/NavigationHeader";
import FooterBar from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "./Banner";
import UmbrellaInsurancePic from "../../images/personal-insurance/UmbrellaInsurance.jpg"


function UmbrellaInsurance() {
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const boxBgColor = useColorModeValue("white", "gray.700");
    const boxShadow = useColorModeValue("lg", "dark-lg");

    const coveragesList = [
        "Libel/slander",
        "False Arrest",
        "Legal Defense Costs for Losses",
        "Liabilities When Traveling",
        "Malicious Prosecution",
        "Increased coverage for Home & Auto"
    ];

    return (
        <Box bg={bgColor} minHeight="100%">
            <TopHeaderBar />
            <NavigationHeader />
            <Box pb={"4em"}>
                <Banner header={"Umbrella Insurance"} footer={"In NY, CT, and MA"} img={UmbrellaInsurancePic} />

                <Container maxW="container.xl">
                    <VStack spacing={6} my={6}>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}

                        >
                            <Heading as="h1" size="xl" mb={6}>
                                Protect Yourself at an Affordable Premium
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                            Umbrella insurance is a type of insurance coverage that goes beyond the standard auto or homeowners policy. Umbrella policies offer extended liability coverage starting at $1 million or more over the existing coverage limits on an auto/home policy.
                            </Text>
                        </Box>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}

                        >
                            <Heading as="h2" size="xl" mb={6}>
                            Do you need umbrella insurance?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                            We always recommend having an umbrella insurance policy if your liability limits are less than the total value of your assets. Umbrella insurance applies to several different situations as opposed to just home or auto. The additional coverage will help when your liability is more than your limits on your home and auto insurance. 
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            width={"100%"}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                What does umbrella insurance cover?
                            </Heading>

                            <UnorderedList spacing={3} mb={6}>
                                {coveragesList.map((type, index) => {
                                    return <ListItem key={index}>{type}</ListItem>;
                                })}
                            </UnorderedList>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                What does this type of insurance not cover?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                            Most umbrella policies do not cover your own injuries, property damage, intentional harm to others, any liability through a legal contract, or business liability. </Text>
                        </Box>

                        <Box
                            p={6}
                            alignSelf="flex-start"
                            width="100%"
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                Save Time and Money with Lia Insurance
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                                If you need umbrella insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different umbrella insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect you.
                            </Text>

                            <Divider borderColor={borderColor} mb={6} />
                            <Link to={"/contact"}>
                                <Button colorScheme="teal" size="lg">
                                    CONTACT US
                                </Button>
                            </Link>

                        </Box>

                    </VStack>
                </Container>
            </Box>
            <FooterBar />
        </Box>
    );
}

export default UmbrellaInsurance;
