import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import App from "./App";
import AgencyPartners from "./AgencyPartners";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import PersonalInsurance from "./PersonalInsurance";
import BusinessInsurance from "./BusinessInsurance";
import HomeInsurance from "./pages/Personal-Insurance/Home";
import RV from "./pages/Personal-Insurance/RV";
import UmbrellaInsurance from "./pages/Personal-Insurance/Umbrella";
import Motorcycle from "./pages/Personal-Insurance/Motorcycle";
import BoatInsurance from "./pages/Personal-Insurance/Boat";
import ValuablePossessionInsurance from "./pages/Personal-Insurance/ValuablePossesion";
import RentersInsurance from "./pages/Personal-Insurance/Renters";
import AutoInsurance from "./pages/Personal-Insurance/Auto";
import OffRoadInsurance from "./pages/Personal-Insurance/OffRoad";
import LiquorLiabilityInsurance from "./pages/Business-Insurance/Liquor";
import CommercialFloodInsurance from "./pages/Business-Insurance/Flood";
import DirectorsAndOfficersInsurance from "./pages/Business-Insurance/Directors";
import CyberSecurityInsurance from "./pages/Business-Insurance/Cybersecurity";
import CommercialUmbrellaInsurance from "./pages/Business-Insurance/Umbrella";
import WorkersCompensationInsurance from "./pages/Business-Insurance/WorkersComp";
import CommercialPropertyInsurance from "./pages/Business-Insurance/Property";
import CommercialAutoInsurance from "./pages/Business-Insurance/Auto";
import GeneralLiabilityInsurance from "./pages/Business-Insurance/Liability";
import BusinessOwnersPolicyInsurance from "./pages/Business-Insurance/BusinessOwner";
import Omissions from "./pages/Business-Insurance/Omissions";
import GetAQuoteEZLynx from "./GetAQuoteEZLynx";
import GetAQuoteEasy from "./GetAQuoteEasy";
import GetAQuoteTool from "./GetAQuoteTool";
import GetAQuote from "./GetAQuote";
import OurTeam from "./OurTeam";
import Careers from "./Careers";
import LandingPage1 from "./pages/blogs/landingPage1";
import LandingPage2 from "./pages/blogs/landingPage2";
import LandingPage3 from "./pages/blogs/landingPage3";
import LandingPage4 from "./pages/blogs/landingPage4";
import LandingPage5 from "./pages/blogs/landingPage5";
import LandingPage6 from "./pages/blogs/landingPage6";
import Sitemap from "./Sitemap";
import BlogPage from "./Blog";
import DefensiveDriving from "./DefensiveDriving";


function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<App />} />
                <Route exact path="/agency-partners" element={<AgencyPartners />} />
                <Route exact path="/file-claim" element={<AgencyPartners />} />
                <Route exact path="/pay-bill" element={<AgencyPartners />} />
                <Route exact path="/contact" element={<ContactUs />} />
                <Route exact path="/quote-full" element={<GetAQuoteEZLynx/>} />
                <Route exact path="/quote-quick" element={<GetAQuoteEasy/>} />
                <Route exact path="/quote-tool" element={<GetAQuoteTool/>} />
                <Route exact path="/quote" element={<GetAQuote/>} />
                <Route exact path="/about" element={<AboutUs />} />
                <Route exact path="/insurance/personal" element={<PersonalInsurance />} />
                <Route exact path="/insurance/business" element={<BusinessInsurance />} />
                <Route exact path="/insurance/personal/home" element={<HomeInsurance heading={{ primary: "Home", secondary: "home" }} description={"Homeowners insurance protects one of the most valuable assests you own. It is important to find the best homeowners insurance policy for the coverage you need. At Lia Insurance we are here to help! Here is some information about homeowners insurance coverage you should know before getting started."} 
                body={"There are two types of homeowners policies offered by insurance companies: actual cash value (ACV) and replacement cost value (RCV). ACV pays you for what your property is worth today, but in most cases if you have a mortgage, lenders will not approve loans with this sort of insurance policy. RCV is the value of replacing your property with a new property, often factoring in depreciation. The insurance carrier will take into consideration things like the cost to rebuild the home, the type of coverage you want or need, the value of the possessions inside, and other structures on the property. The cost of your home will be determined by a cost estimator, which is valued on price per square foot. Any upgraded features such as bathroom or kitchen will change the estimated cost of your home. The value of your possessions inside the home are typically based on a percentage of the cost of your dwelling. If you need additional coverage you can do additional increases through endorsements."} 
                covered={["Vandalism", "Fire", "Windstorm", "Hail", "Lightning", "Smoke damage", "Pipe burst - Any flood caused by a pipe bursting is covered", "Theft - There are limitations"]} notCovered={["Floods - Any natural flood such as rain, melting snow, hurricane - most home insurance policies do not cover. You would need to purchase additional insurance to cover any floods. Typically if you have a mortgage and live in a high risk flood area, your bank will require you purchase flood insurance to obtain your loan.", "Earthquakes can be covered under a homeowners insurance policy by endorsement only. They are subject to its own separate deductible.", `"Acts of God" -- any event that occurs outside of human control and that can't be predicted or prevented. The term is roughly analogous to a natural disaster. Things like earthquakes, severe weather and floods are all considered acts of God. However, fallen trees and lightning strikes are often covered. Make sure you check with your agent.`,
                `War`, `Intentional Damage`, `General Wear and Tear`, `Mold`, `Rodent or insect damage`, `Uninsured vehicles on the property`]} 
                footer={"If you are new homebuyers looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop different home insurance carriers to find the best deal for you!"} />} />
                <Route exact path="/insurance/personal/rv" element={<RV />}/>
                <Route exact path="/insurance/personal/umbrella" element={<UmbrellaInsurance/>}/>
                <Route exact path="/insurance/personal/motorcycle" element={<Motorcycle/>}/>
                <Route exact path="/insurance/personal/boat" element={<BoatInsurance  />}/>
                <Route exact path="/insurance/personal/valuable-possessions" element={<ValuablePossessionInsurance  />}/>
                <Route exact path="/insurance/personal/renters" element={<RentersInsurance  />}/>
                <Route exact path="/insurance/personal/offroad" element={<OffRoadInsurance  />}/>
                <Route exact path="/our-team" element={<OurTeam />} />
                <Route exact path="/careers" element={<Careers />} />
                <Route exact path="/insurance/personal/auto" element={<AutoInsurance/>} />
                <Route exact path="/insurance/business/liquor" element={<LiquorLiabilityInsurance/>} />
                <Route exact path="/insurance/business/flood" element={<CommercialFloodInsurance/>} />
                <Route exact path="/insurance/business/directors" element={<DirectorsAndOfficersInsurance/>} />
                <Route exact path="/insurance/business/cybersecurity" element={<CyberSecurityInsurance/>} />
                <Route exact path="/insurance/business/commercial-umbrella" element={<CommercialUmbrellaInsurance/>} />
                <Route exact path="/insurance/business/workers-compensation" element={<WorkersCompensationInsurance/>} />
                <Route exact path="/insurance/business/commercial-property" element={<CommercialPropertyInsurance/>} />
                <Route exact path="/insurance/business/business-auto" element={<CommercialAutoInsurance/>} />
                <Route exact path="/insurance/business/general-liability" element={<GeneralLiabilityInsurance/>} />
                <Route exact path="/insurance/business/business-owners" element={<BusinessOwnersPolicyInsurance/>} />
                <Route exact path="/insurance/business/omissions" element={<Omissions/>} />
                <Route exact path="/landing1" element={<LandingPage1/>} />
                <Route exact path="/landing2" element={<LandingPage2/>} />
                <Route exact path="/sitemap" element={<Sitemap/>} />
                <Route exact path="/blog" element={<BlogPage/>} />
                <Route exact path="/defensive-driving-course" element={<DefensiveDriving/>} />
                <Route exact path="/blog/1" element={<LandingPage1/>} />
                <Route exact path="/blog/2" element={<LandingPage2/>} />
                <Route exact path="/blog/3" element={<LandingPage3/>} />
                <Route exact path="/blog/4" element={<LandingPage4/>} />
                <Route exact path="/blog/5" element={<LandingPage5/>} />
                <Route exact path="/blog/6" element={<LandingPage6/>} />
            </Routes>

        </BrowserRouter>
    )
}

export default Router;



