import React from "react";
import { Card, CardBody, Stack, Image, Divider, CardFooter, ButtonGroup, Button, Heading } from "@chakra-ui/react"
import { useImages } from "../ImageContext";

function AgencyCard({ phoneNumber, website, name, header }) {
    const { images } = useImages()
    const imgUrl = images[name]

    return (
        <Card maxW='sm'>
            <CardBody>
                <Image
                    src={imgUrl}
                    alt={`${name} insurance`}
                    borderRadius='lg'
                    mx={"auto"}
                />
            </CardBody>
            <Stack>
                <Heading fontSize={'md'} mx={"auto"}>{header}</Heading>
            </Stack>
            <Divider />
            <CardFooter>
                    <ButtonGroup spacing='2' mx={"auto"}>
                        <Button as="a" href={website} variant='solid' colorScheme='blue'>
                            Website
                        </Button>
                        <Button as="a" href={`tel:${phoneNumber}`} variant='solid' colorScheme='purple'>
                            Call 
                        </Button>

                    </ButtonGroup>
            </CardFooter>
        </Card>
    )



}

export default AgencyCard