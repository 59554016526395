import { Box, Heading, Text, Link, Image, VStack, useBreakpointValue } from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import TopHeaderBar from "../../components/Top";
import Footer from "../../components/Footer";
import GetAQuote from "../../components/GetAQuote";
import Pic1 from "../../images/blog/1.png";
import Pic2 from "../../images/blog/2.png";
import Pic3 from "../../images/blog/3.png";

const LandingPage2 = () => {
  const textWidth = useBreakpointValue({ base: "100%", md: "80%", lg: "50%" });
  const fontSize = useBreakpointValue({ base: "md", md: "lg" });
  const headingSize = useBreakpointValue({ base: "2xl", md: "3xl" });
  const subHeadingSize = useBreakpointValue({ base: "xl", md: "2xl" });

  return (
    <Box bg="gray.100" minHeight="100vh" fontFamily="Arial, sans-serif">
      <TopHeaderBar />
      <NavigationHeader />
      <VStack spacing={10} py={12} w="100%" px={[4, 8, 12]} align="center" bg="white" boxShadow="md" borderRadius="md">
        <Heading as="h1" fontSize={headingSize} color="blue.700" fontWeight="bold">Managing Rising Insurance Costs: What can you do to save?</Heading>

        <Image src={Pic1} alt="Image 1" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          In this current landscape of escalating rates, primarily influenced by factors beyond our immediate control, there are proactive measures you can take to mitigate the impact of rising auto and home insurance expenses.
        </Text>

        <Image src={Pic2} alt="Image 2" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

        <Heading as="h2" fontSize={subHeadingSize} color="blue.600">Auto Insurance Optimization:</Heading>
        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Accident Prevention Course: An online accident prevention course can yield a 10% reduction on the liability portion of your premium for each participant completing the course. This reduction is applicable for three years on the assigned vehicle. Our NYS Accident Prevention Course is readily accessible.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Multi-Policy Discount: Consider consolidating your auto and home policies with a single insurance provider. Such bundling can often unlock valuable discounts. Additional policies like renters, business, recreational vehicles, or boats might also qualify for reduced rates.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Raise Deductibles: Provided there are no stipulations in a finance or lease agreement, increasing deductibles for comprehensive and collision coverage can help lower costs.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Telematics/Drive Tracking: Opting for telematics with a tracking device can yield discounts. This system generally tracks metrics like hard braking, fast acceleration, late-night driving, and total driving time.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Good Student Discount: If there are students covered under your policy, they may be eligible for a discount with a B grade average or better, validated via a current report card.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Homeowner Discount: Even if your auto insurance isn't through the same company as your home insurance, owning a home can often lead to a discount on auto coverage.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Automatic Payments: Opting for automatic payments can make you eligible for discounts from your insurance provider.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Pay in Full: While not feasible for everyone, paying your policy premium in full can lead to significant discounts.
        </Text>

        <Image src={Pic3} alt="Image 3" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

        <Heading as="h2" fontSize={subHeadingSize} color="blue.600">Homeowners Insurance Insights:</Heading>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Multi-Policy Discount: Similar to auto insurance, bundling multiple policies with the same insurance provider for your homeowners insurance can bring about favorable discounts.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Protective Device Discount: Various safety devices such as doorbell cameras, security systems, and leak detection devices may lead to discounts on your homeowners insurance.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          New or Renovated Home Discount: If your home is newly constructed or recently underwent significant renovations, potential savings may be on the horizon. Updates to your roof, electrical systems, plumbing, and heating are often of prime interest to insurers.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Increase Deductibles: If you're comfortable managing higher out-of-pocket expenses in the event of losses, increasing your deductible can lower your premium.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          Pay in Full: When feasible, paying your homeowners policy premium in full can make you eligible for discounts.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6" mt={6}>
          We understand the weight that rising insurance costs carry in your life. While these discounts might not be universally applicable, alternative strategies are available to address your concerns and manage expenses.
        </Text>

        <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
          If you have inquiries about these or other potential discounts, please don't hesitate to 
          <Link href="/path-to-contact" color="blue.600" fontWeight="medium" _hover={{ textDecoration: 'underline' }}> reach out</Link>. 
          We're here to assist in any way possible.
        </Text>
      </VStack>
      <Footer />
      <GetAQuote />
    </Box>
  );
};

export default LandingPage2;










































// import { Box, Heading, Text, Link, Image, VStack } from "@chakra-ui/react";
// import NavigationHeader from "../../components/NavigationHeader";
// import TopHeaderBar from "../../components/Top";
// import Footer from "../../components/Footer";
// import GetAQuote from "../../components/GetAQuote";
// import Pic1 from "../../images/blog/1.png"
// import Pic2 from "../../images/blog/2.png"
// import Pic3 from "../../images/blog/3.png"

// const LandingPage2 = () => {
//   return (
//     <Box bg="gray.100" minHeight="100vh" fontFamily="Arial, sans-serif">
//       <TopHeaderBar />
//       <NavigationHeader />
//       <VStack spacing={10} py={12} w="80%" align={"center"} m="auto" bg="white" boxShadow="md" borderRadius="md" p={8}>
//         <Heading as="h1" fontSize="3xl" color="blue.700" fontWeight="bold">Managing Rising Insurance Costs: What can you do to save?</Heading>
        
//         <Image src={Pic1} alt="Image 1" boxShadow="sm" borderRadius="md" />

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           In this current landscape of escalating rates, primarily influenced by factors beyond our immediate control, there are proactive measures you can take to mitigate the impact of rising auto and home insurance expenses.
//         </Text>

//         <Image src={Pic2} alt="Image 2" boxShadow="sm" borderRadius="md" />

//         <Heading as="h2" fontSize="2xl" color="blue.600">Auto Insurance Optimization:</Heading>
//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Accident Prevention Course: An online accident prevention course can yield a 10% reduction on the liability portion of your premium for each participant completing the course. This reduction is applicable for three years on the assigned vehicle. Our NYS Accident Prevention Course is readily accessible.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Multi-Policy Discount: Consider consolidating your auto and home policies with a single insurance provider. Such bundling can often unlock valuable discounts. Additional policies like renters, business, recreational vehicles, or boats might also qualify for reduced rates.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Raise Deductibles: Provided there are no stipulations in a finance or lease agreement, increasing deductibles for comprehensive and collision coverage can help lower costs.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Telematics/Drive Tracking: Opting for telematics with a tracking device can yield discounts. This system generally tracks metrics like hard braking, fast acceleration, late-night driving, and total driving time.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Good Student Discount: If there are students covered under your policy, they may be eligible for a discount with a B grade average or better, validated via a current report card.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Homeowner Discount: Even if your auto insurance isn't through the same company as your home insurance, owning a home can often lead to a discount on auto coverage.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Automatic Payments: Opting for automatic payments can make you eligible for discounts from your insurance provider.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Pay in Full: While not feasible for everyone, paying your policy premium in full can lead to significant discounts.
//         </Text>

//         <Image src={Pic3} alt="Image 3" boxShadow="sm" borderRadius="md" />

//         <Heading as="h2" fontSize="2xl" color="blue.600">Homeowners Insurance Insights:</Heading>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Multi-Policy Discount: Similar to auto insurance, bundling multiple policies with the same insurance provider for your homeowners insurance can bring about favorable discounts.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Protective Device Discount: Various safety devices such as doorbell cameras, security systems, and leak detection devices may lead to discounts on your homeowners insurance.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           New or Renovated Home Discount: If your home is newly constructed or recently underwent significant renovations, potential savings may be on the horizon. Updates to your roof, electrical systems, plumbing, and heating are often of prime interest to insurers.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Increase Deductibles: If you're comfortable managing higher out-of-pocket expenses in the event of losses, increasing your deductible can lower your premium.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           Pay in Full: When feasible, paying your homeowners policy premium in full can make you eligible for discounts.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6" mt={6}>
//           We understand the weight that rising insurance costs carry in your life. While these discounts might not be universally applicable, alternative strategies are available to address your concerns and manage expenses.
//         </Text>

//         <Text w={"50%"} fontSize="lg" color="gray.800" lineHeight="1.6">
//           If you have inquiries about these or other potential discounts, please don't hesitate to 
//           <Link href="/path-to-contact" color="blue.600" fontWeight="medium" _hover={{ textDecoration: 'underline' }}> reach out</Link>. 
//           We're here to assist in any way possible.
//         </Text>
//       </VStack>
//       <Footer />
//       <GetAQuote />
//     </Box>
//   );
// };

// export default LandingPage2;

