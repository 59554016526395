import React from "react";
import { ChakraProvider, Text, Heading, Flex, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom"
import theme from "../../theme"

function Summary() {
    return (
        <ChakraProvider theme={theme}>
            <Flex direction={"column"} width={["90vw", "80vw", "70vw", "50vw"]} mx={"auto"} mb={"2em"} mt={"2em"}>
                <Heading mx={"auto"}>Our Mission</Heading>


                <Text color={"gray.500"} fontSize={["1em", "1.1em", "1.2em", "1.2em"]} mt={"1em"} fontWeight={"600"} fontFamily={"body"} >
                    Here at Lia Insurance Agency we give our clients the power of choice from our top-rated agency partners. We strive to provide the best customer service and the best price to meet our client's needs.
                </Text>
                <Text color={"gray.500"} fontSize={["1em", "1.1em", "1.2em", "1.2em"]} mt={"1em"} fontWeight={"600"} fontFamily={"body"} >
                    We provide fast and easy insurance quotes that take only 25 minutes! We offer superior protection for your car, home or business. As an independent insurance agent, we have lots of agency partners to shop from which allows us to provide you the right coverage at the right price. Shopping for insurance can be overwhelming, so let us do the work for you.
                </Text>


                <Flex direction={["column", "row"]} justifyContent={"space-between"} mt={"2em"}>
                    <Link to={"/about"}>
                        <Button
                            borderRadius={"20"}
                            bg={"#BC3B4A"}
                            color={"white"}
                            mb={["1em", "0"]}
                            size={"lg"}
                            _hover={{ bg: "#FC3B4A", border: "none" }}
                            width={["100%", "auto"]}
                        >LEARN MORE</Button>
                    </Link>
                    <Link to={"/quote"}>
                        <Button
                            borderRadius={"20"}
                            bg={"#5B5F97"}
                            color={"white"}
                            size={"lg"}
                            _hover={{ bg: "#5F5FF7", border: "none" }}
                            width={["100%", "auto"]}
                        >GET A QUOTE</Button>
                    </Link>


                </Flex>

            </Flex>
        </ChakraProvider>
    )
}

export default Summary