import * as React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import "./index.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Router from './Router';
import { ImageProvider } from './ImageContext';
import theme from './theme';


ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ImageProvider>
      {/* <CSSReset /> */}
        <Router/>
      </ImageProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
