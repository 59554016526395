import {
  Box,
  Heading,
  Text,
  Button,
  UnorderedList,
  ListItem,
  Divider,
  VStack,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import NavigationHeader from "../../components/NavigationHeader";
import TopBar from "../../components/Top";
import Banner from "./Banner";
import AutoInsurancePic from "../../images/personal-insurance/CarInsurance.jpg";
import Quote from "../../components/GetAQuote"

function AutoInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const savingsList = [
    "Defensive driving course (hyperlink to defensive driving) - Good for three years", //hyperlink this later
    "Annual payment plans vs. monthly payments",
    "Autopay discounts",
    "Accident history",
    "Clean driving record",
    "Good credit history",
    "Shop different carriers - at Lia we do that for you!",
    "Raise your deductible",
    "Bundle your insurance (get home and auto from the same carrier)",
    "Low Mileage discounts",
    "Brand of car you drive",
  ];

  return (
    <Box bg={bgColor} minHeight="100%">
      <TopBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"Auto Insurance"}
          footer={
            "Car and Truck Insurance - We Get You The Coverage You Need at an Affordable Price"
          }
          img={AutoInsurancePic}
        />
        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                Risks drivers may face
              </Heading>
              <UnorderedList>
                <ListItem>You cause an accident</ListItem>
                <ListItem>You accidentally damage your own car</ListItem>
                <ListItem>Hailstorm/weather events/animal damages</ListItem>
                <ListItem>An uninsured driver hits you</ListItem>
                <ListItem>You can't work due to injury from a car accident</ListItem>
              </UnorderedList>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What kind of coverage do I need?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Most states require drivers to have liability insurance when they own a vehicle. At the very least you must by your state's minimum requirements. Drivers who lease or finance their vehicle are often required by the bank to also have comprehension and collision coverage.
              </Text>
            </Box>
            <Box
              p={6}
              alignSelf="flex-start" // Add this line
              width="100%" // Add this line
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h3" size="lg" mb={4}>
                How can I save money on my car insurance?
              </Heading>

              <UnorderedList spacing={3} mb={6}>
                {savingsList.map((type, index) => {
                  return <ListItem key={index}>{type}</ListItem>;
                })}
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h3" size="lg" mb={4}>
                We Also Offer Classic Car Insurance!
              </Heading>

              <Text fontSize="lg" mb={"1.5em"}>
                Classic cars are considered to be at least 10 years old and have historical interest, but each insurer has different qualifications. Classic car insurance will cover liability, comprehensive, collision, and other specialized coverages. It works similarly to a regular auto policy with the added benefit of having coverage for the vehicle's agreed value.
              </Text>
              <UnorderedList>
                <ListItem>It cannot be a daily vehicle</ListItem>
                <ListItem>It is preferred that the car is stored in a private garage, barn, or storage unit</ListItem>
                <ListItem>All drivers in the household must have other vehicles</ListItem>
              </UnorderedList>
              <Divider borderColor={borderColor} mb={6} />
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
              Talk with Lia Insurance today to make sure your car has the proper coverage in place to protect your family and your car.
              </Heading>
              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Quote />
    </Box>
  );
}

export default AutoInsurance;
