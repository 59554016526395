import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
      bg: "#FFFFFB",
      primary: {
        500: '#BC3B4A',
      },
      secondary: {
        500: '#FFC145',
      },
      button: {
        500: '#5B5F97',
      },
    },
    fonts: {
      body: 'Avenir, sans-serif',
      heading: 'Avenir, sans-serif'
    }
  });

export default theme;