import { Box, Heading, Text, Image, VStack, useBreakpointValue, UnorderedList, ListItem } from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import TopHeaderBar from "../../components/Top";
import Footer from "../../components/Footer";
import GetAQuote from "../../components/GetAQuote";
import Pic1 from "../../images/blog/blog4image1.jpg";
import Pic2 from "../../images/blog/blog4image2.jpg";


const LandingPage4 = () => {
    const textWidth = useBreakpointValue({ base: "100%", md: "80%", lg: "50%" });
    const fontSize = useBreakpointValue({ base: "md", md: "lg" });
    const headingSize = useBreakpointValue({ base: "2xl", md: "3xl" });
    const subHeadingSize = useBreakpointValue({ base: "xl", md: "2xl" });

    return (
        <Box bg="gray.100" minHeight="100vh" fontFamily="Arial, sans-serif">
            <TopHeaderBar />
            <NavigationHeader />
            <VStack spacing={10} py={12} w="100%" px={[4, 8, 12]} align="center" bg="white" boxShadow="md" borderRadius="md">
                <Heading as="h1" fontSize={headingSize} color="blue.700" fontWeight="bold">Water Backup vs. Flood Coverage: What's the Difference?



                </Heading>

                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    Water damage can be a nightmare for homeowners, but did you know there are different types of insurance coverage to protect against it? Understanding the difference between water backup and flood coverage is crucial for safeguarding your home from unexpected water-related disasters. In this guide, we'll break down the basics in easy-to-understand terms and help you navigate the ins and outs of insurance coverage.


                </Text>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    Keep in mind, each company may have different coverages, limitations, and exclusions. Always

                    consult your insurance agent or broker for specifics on your policy.
                </Text>

                <Image src={Pic1} alt="Liability Coverage" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">Water Backup Coverage:





                </Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    Water backup coverage, also known as sewer backup coverage, is an optional coverage that can be added to a property policy like homeowner’s, renters, or business. The coverage kicks in when water or sewage backs up into your home or business through drains, toilets, sinks, or sump pumps. Think of it as protection against internal plumbing mishaps.

                    <Heading as="h3" fontSize={subHeadingSize} color="blue.600">Key Points about Water Backup Coverage:


                    </Heading>
                    <UnorderedList mt={"2em"} mb={"2em"}>
                        <ListItem ListIcon="ul">What it Covers: This coverage helps pay for damages caused by sudden and accidental water backups within your home. It can include repairs to walls, floors, furniture, and belongings damaged by plumbing issues like clogged pipes or sump pump failures.
                        </ListItem>
                        <ListItem>Causes of Damage: It covers issues related to your home's plumbing system, such as blockages, overflows, or failures that lead to water backup.


                        </ListItem>
                        <ListItem>Limits: There are usually coverage limits, typically $5000, $10,000 or $25,000 so be sure to check your policy. Some policies include the coverage, others may offer water backup coverage as an optional add-on to your existing property insurance policy. It’s also important to keep in mind that a damaged or broken sump pump may not be included in this coverage, unless equipment breakdown is on the policy.


                        </ListItem>
                    </UnorderedList>
                </Text>
                <Image src={Pic2} alt="PIC 2" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />


                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">Flood Coverage:

                </Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    Flood coverage, on the other hand, steps in when water enters your home from outside sources like heavy rain, rising rivers, storm surges, or snow melt. It's there to protect you when Mother Nature throws a curveball.
                </Text>

                <Heading as="h3" fontSize={subHeadingSize} color="blue.600">Key Points about Flood Coverage:

                </Heading>
                <UnorderedList mt={"2em"} mb={"2em"}>
                    <ListItem ListIcon="ul">What it Covers: Flood insurance protects against damage from external flooding that affects multiple properties or at least 2 acres of land. This includes structural damage to your home, foundation, walls, floors, and personal belongings.

                    </ListItem>
                    <ListItem>Designated Flood Zones: Flood insurance may be required if you live in a designated flood zone.



                    </ListItem>
                    <ListItem>National Flood Insurance Program (NFIP): Flood insurance is often provided through the NFIP, administered by FEMA, but private insurers may also offer coverage with similar protection.



                    </ListItem>
                    <ListItem>Waiting Period: Keep in mind that flood insurance policies usually have a 30-day waiting period before coverage kicks in, so plan ahead and get coverage well before any potential flood events.


                    </ListItem>
                </UnorderedList>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                Water backup and flood coverage serve different purposes, but both are crucial for protecting your home from water damage. While water backup coverage deals with internal plumbing issues, flood coverage handles external flooding events. By understanding these differences and reviewing your insurance policies, you can ensure your home is adequately protected against the unexpected. Don't wait until it's too late—take the necessary steps to safeguard your home and peace of mind today. Give us a call or email if you have questions or want to add one of these coverages.

                </Text>
                
            </VStack>
            <Footer />
            <GetAQuote />
        </Box>
    );
};

export default LandingPage4;