import { Box, Heading, Text, Image, VStack, useBreakpointValue, UnorderedList, ListItem } from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import TopHeaderBar from "../../components/Top";
import Footer from "../../components/Footer";
import GetAQuote from "../../components/GetAQuote";
import Pic1 from "../../images/blog/blog5img1.png";
import Pic2 from "../../images/blog/blog5img2.jpg";
import Pic3 from "../../images/blog/blog5img3.png";
import Pic4 from "../../images/blog/blog5img4.png";

const LandingPage5 = () => {
    const textWidth = useBreakpointValue({ base: "100%", md: "80%", lg: "50%" });
    const fontSize = useBreakpointValue({ base: "md", md: "lg" });
    const headingSize = useBreakpointValue({ base: "2xl", md: "3xl" });
    const subHeadingSize = useBreakpointValue({ base: "xl", md: "2xl" });

    return (
        <Box bg="gray.100" minHeight="100vh" fontFamily="Arial, sans-serif">
            <TopHeaderBar />
            <NavigationHeader />
            <VStack spacing={10} py={12} w="100%" px={[4, 8, 12]} align="center" bg="white" boxShadow="md" borderRadius="md">
                <Heading as="h1" fontSize={headingSize} color="blue.700" fontWeight="bold">
                    Getting Your Home Ready Before a Vacation: A Comprehensive Checklist
                </Heading>

                <Image src={Pic1} alt="Vacation preparation" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    Planning for a vacation involves more than just packing your bags and booking your travel. Ensuring that your home is prepared before you leave can save you from worries while you're away and prevent potential issues upon your return. Here's a comprehensive checklist to help you get your home vacation-ready:
                </Text>

                <Image src={Pic2} alt="Home security" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">1. Security Measures</Heading>
                <UnorderedList w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <ListItem><strong>Secure Doors and Windows:</strong> Double-check all doors and windows to ensure they are locked.</ListItem>
                    <ListItem><strong>Set Your Alarm System:</strong> Make sure it's armed before you leave.</ListItem>
                    <ListItem><strong>Hide Valuables:</strong> Store valuable items in a safe or hidden place.</ListItem>
                    <ListItem><strong>Use Timers for Lights:</strong> Install timers on indoor and outdoor lights.</ListItem>
                    <ListItem><strong>Inform a Trusted Neighbor or Friend:</strong> Ask them to keep an eye on your property.</ListItem>
                </UnorderedList>

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">2. Mail and Deliveries</Heading>
                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <strong>Hold Mail and Packages:</strong> Place a hold on your mail through the postal service, or ask a neighbor to collect it for you.
                </Text>

                <Image src={Pic3} alt="Home appliances" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">3. Appliance and Utility Management</Heading>
                <UnorderedList w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <ListItem><strong>Unplug Electronics:</strong> Unplug non-essential electronics to save energy and protect them from power surges.</ListItem>
                    <ListItem><strong>Adjust Thermostat:</strong> Set your thermostat to an energy-saving mode.</ListItem>
                    <ListItem><strong>Turn Off the Water Supply:</strong> Turn off the water supply to prevent potential leaks or pipe bursts.</ListItem>
                </UnorderedList>

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">4. Pet Care</Heading>
                <UnorderedList w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <ListItem><strong>Arrange for Pet Care:</strong> Arrange for a pet sitter or boarding.</ListItem>
                    <ListItem><strong>Stock Up on Supplies:</strong> Ensure you have enough food, litter, and other supplies for your pet's caretaker.</ListItem>
                </UnorderedList>

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">5. Yard and Exterior Maintenance</Heading>
                <UnorderedList w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <ListItem><strong>Lawn Care:</strong> Mow your lawn and trim any overgrown bushes or trees before you leave.</ListItem>
                    <ListItem><strong>Outdoor Furniture:</strong> Secure outdoor furniture, decorations, and grills to prevent damage from wind or storms.</ListItem>
                </UnorderedList>

                <Image src={Pic4} alt="Home cleaning" boxShadow="sm" borderRadius="md" maxW={["100%", "80%", "60%"]} />

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">6. Home Cleaning</Heading>
                <UnorderedList w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <ListItem><strong>Clean the Kitchen:</strong> Dispose of perishable food items and take out the trash.</ListItem>
                    <ListItem><strong>Laundry:</strong> Do your laundry and change the bed sheets.</ListItem>
                </UnorderedList>

                <Heading as="h2" fontSize={subHeadingSize} color="blue.600">7. Final Checks</Heading>
                <UnorderedList w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6">
                    <ListItem><strong>Double-Check Itinerary and Contacts:</strong> Ensure you have your travel itinerary and emergency contacts readily accessible.</ListItem>
                    <ListItem><strong>Backup Important Data:</strong> Back up important data to an external drive or cloud service.</ListItem>
                    <ListItem><strong>Last-Minute Walkthrough:</strong> Do a final walkthrough of your home to ensure everything is in order.</ListItem>
                </UnorderedList>

                <Text w={textWidth} fontSize={fontSize} color="gray.800" lineHeight="1.6" mt={6}>
                    Taking the time to prepare your home before a vacation can give you peace of mind and help you avoid potential problems. By following this checklist, you can ensure your home is secure, your belongings are safe, and you can fully enjoy your vacation knowing everything is taken care of. Safe travels!
                </Text>
            </VStack>
            <Footer mb={9} />
            <GetAQuote />
        </Box>
    );
};

export default LandingPage5;